<template>
  <ASelect
    ref="selectRef"
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Fungsi"
    allow-clear
    :not-found-content="state.loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    :loading="state.loading"
    :open="state.dropdownOpen"
    @search="onSearch"
    @dropdownVisibleChange="handleDropdownVisibleChange"
  >
    <ASelectOption
      v-for="d in state.data"
      :key="d.function"
      :label="d.function"
    >
      <span v-html="highlight(d.function)"></span>
    </ASelectOption>

    <template v-if="state.loading" #notFoundContent>
      <ASpin size="small" />
    </template>

    <template v-if="is_create" #dropdownRender="{ menuNode: menu }">
      <v-nodes :vnodes="menu" />
      <ADivider style="margin: 4px 0" />
      <div class="m-2" ref="dropdownContent">
        <AInput
          v-model:value="state.form.function"
          placeholder="Masukkan fungsi baru"
          @keydown.enter.prevent="addItemFunction"
        />
        <AButton
          type="primary"
          block
          class="mt-2"
          @mousedown.prevent="addItemFunction"
        >
          <PlusOutlined /> Tambah Fungsi
        </AButton>
      </div>
    </template>
  </ASelect>
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'
import { PlusOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    PlusOutlined,
    VNodes: (_, { attrs }) => attrs.vnodes,
  },
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      type: String,
      default: 'multiple',
    },
    is_create: {
      type: [Boolean],
      default: () => false,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const state = reactive({
      endpoint: '/api/filter/synchron-function',
      loading: false,
      data: [],
      params: {
        q: '',
      },
      form: {
        function: '',
      },
      dropdownOpen: false,
    })

    const selectRef = ref(null)
    const dropdownContentRef = ref(null)

    const fetchData = () => {
      state.loading = true
      apiClient
        .get(state.endpoint, {
          params: state.params,
        })
        .then(({ data }) => {
          state.data = data
        })
        .finally(() => {
          state.loading = false
        })
    }

    const onSearch = value => {
      state.params.q = value
    }

    const addItemFunction = () => {
      const trimmedItem = state.form.function.trim()
      if (trimmedItem) {
        state.data.push({ function: trimmedItem })
        state.form.function = ''
        state.dropdownOpen = true // Tetap buka dropdown
      }
    }

    const highlight = (value) => {
      if (!value) return value
      return value.replace(new RegExp(state.params.q, 'gi'), (match) => {
        return `<span style="background-color: yellow">${match}</span>`
      })
    }

    const handleDropdownVisibleChange = (visible) => {
      const selectElement = selectRef.value?.$el
      const dropdownContentElement = dropdownContentRef.value

      if (
        visible || // Jika dropdown dibuka secara eksplisit
        (selectElement?.contains(event.target) || dropdownContentElement?.contains(event.target))
      ) {
        // Jangan tutup dropdown jika klik terjadi di dalam select atau dropdown content
        state.dropdownOpen = true
      } else {
        // Tutup dropdown jika klik terjadi di luar elemen
        state.dropdownOpen = false
      }
    }

    onMounted(() => {
      fetchData()
    })

    watch(state.params, debounce(() => {
      fetchData()
    }, 300))

    return {
      valueState: useVModel(props, 'value'),
      state,
      selectRef,
      dropdownContentRef,
      highlight,
      onSearch,
      handleDropdownVisibleChange,
      addItemFunction,
    }
  },
}
</script>
