<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <ATabs
            v-model:activeKey="state.active"
            @change="handleTabChange">
            <ATabPane key="1" tab="Raw Query">
                <ATextarea
                    placeholder="raw query" 
                    rows="4"
                    v-model:value="state.params.query"/>
            </ATabPane>
            <ATabPane key="2" tab="Tables">
                <ARow :gutter="[8,0]">
                    <ACol :span="6">
                        <FilterDatabaseTable
                            style="width:100%;"
                            :mode="null"
                            v-model:value="state.params.table"/>
                    </ACol>
                </ARow>
                <section class="my-2">
                    <article
                        v-for="(item, index) in state.fields"
                        :key="index"
                        :class="{
                            'mt-2': index > 0,
                        }">
                        <ARow
                            :gutter="[8,0]">
                            <ACol :span="6">
                                <FilterDatabaseTableColumn
                                    style="width:100%;"
                                    :mode="null"
                                    :disabled="!state.params.table"
                                    v-model:table="state.params.table"
                                    v-model:value="item.field"/>
                            </ACol>

                            <ACol :span="6">
                                <FilterDatabaseOperator
                                    style="width:100%;"
                                    :mode="null"
                                    :disabled="!state.params.table"
                                    v-model:table="state.params.table"
                                    v-model:value="item.operator"/>
                            </ACol>

                            <ACol :span="6">
                                <ASelect
                                    v-if="_.includes(['IN', 'NOT IN'], item.operator)"
                                    style="width:100%;"
                                    placeholder="Value"
                                    mode="tags"
                                    :token-separators="[',']"
                                    v-model:value="item.valuein"/>
                                <AInput
                                    v-else
                                    style="width:100%;"
                                    placeholder="Value"
                                    :disabled="!state.params.table"
                                    v-model:value="item.value"/>
                            </ACol>

                            <ACol :span="1" v-if="!item.id">
                                <APopconfirm
                                    title="Apa Anda yakin?"
                                    ok-text="Iya"
                                    placement="left"
                                    cancel-text="Tidak"
                                    @confirm="removeRowField(item)">
                                    <AButton
                                        type="danger"
                                        :disabled="!state.params.table">
                                        <i class="fa fa-trash" />
                                    </AButton>
                                </APopconfirm>
                            </ACol>
                        </ARow>
                    </article>
                </section>

                <ASpace>
                    <AButton
                        type="dashed"
                        size="small"
                        @click="addField"
                        :disabled="!state.params.table">
                        <i class="fa fa-plus mr-2" /> Tambah Filter
                    </AButton>
                </ASpace>

            </ATabPane>
        </ATabs>

        <ASpace>
            <AButton
                type="primary"
                title="cari"
                class="mt-2"
                @click="fetchDataList"
                :loading="state.isFetching">
                <span
                    v-if="!state.isFetching"
                    class="fa fa-search"
                    aria-hidden="true"/>
                <span v-else>
                    Memuat Data ...
                </span>
            </AButton>
        </ASpace>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="id"
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import FilterDatabaseTable from '@/components/filter/FilterDatabaseTable'
import FilterDatabaseTableColumn from '@/components/filter/FilterDatabaseTableColumn'
import FilterDatabaseOperator from '@/components/filter/FilterDatabaseOperator'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import apiClient from '@/services/axios'
import QueryBuilder from '@/helpers/querybuilder'
import _ from 'lodash'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FilterDatabaseTable,
        FilterDatabaseTableColumn,
        FilterDatabaseOperator,
        DownloadExcel,
    },
    setup() {
        const route = useRoute()
        const errorMessage = ref()
        const state = reactive({
            columns: [],
            endpoint:'/api/query/check',
            data: [],
            meta: {
                per_page: 30,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                active: 1,
                query: '',
                table: null,
                sort: null,
            }),
            fields: [],
            field: {
                field: null,
                operator: null,
                value: null,
                valuein: [],
            },
            active: route.query.active || '1',
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            // set order by
            state.params.sort = sorter.columnKey && sorter.order
                ? `${sorter.columnKey}.${sorter.order == 'ascend' ? 'ASC': 'DESC'}`
                : null

            fetchDataList()
        }

        const handleTabChange = (activeKey) => {
            state.params.active = activeKey

            state.columns.forEach(item => {
                item.sorter = activeKey == '2'
            })
        }

        const isConditionValid = (condition) => {
            return _.every(['field', 'operator'], key => _.has(condition, key) && !_.isEmpty(condition[key]))
        }

        // Mengonversi kunci menjadi format { title, dataIndex }
        const convertKeys = (items) => {
            const [column, order] = _.split(state.params.sort, '.')

            return _.keys(items).map(key => ({
                title: key,
                dataIndex: key,
                sorter: state.params.active == 2,
                sortOrder: column === key ? (order === "DESC" ? "descend" : "ascend") : null,
            }))
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.table && state.active == 2) {
                QueryBuilder.initialize()
                    .select()
                    .from(state.params.table)
    
                state.fields.forEach(item => {
                    if (isConditionValid(item)) {
                        let value = _.includes(['IN', 'NOT IN'], item.operator) ? item.valuein : item.value
                        QueryBuilder.where(item.field, item.operator, value)
                    }
                })

                if (state.params.sort) {
                    const [column, order] = _.split(state.params.sort, '.')
                    QueryBuilder.orderBy(column, order)
                }

                QueryBuilder.build()
                
                params.query = QueryBuilder.debugQuery()
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            // reset
            state.columns = []
            state.data = []

            apiClient
                .post(state.endpoint, queryParams())
                .then(({ data }) => {
                    const { result } = data

                    if (result.items) {
                        state.columns = convertKeys(result.items[0])
                        state.data = result.items
                    }

                    state.meta.page = result._meta.currentPage
                    state.meta.per_page = result._meta.perPage
                    state.meta.total = result._meta.totalCount
                })
                .catch(({ response: { data, status } }) => {
                    if (status === 400) {
                        errorMessage.value = data.message
                    }
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const addField = () => {
            if (state.fields.filter(v => v.code === null).length > 0) {
                message.warning('Pastikan field sebelumnya terisi!')
                return
            }
            
            state.fields.push(Object.assign({}, state.field))
        }

        const removeRowField = (item) => {
            const index = state.fields.indexOf(item)

            if (item !== -1) {
                state.fields.splice(index, 1)
            }
        }

        // handle vue
        onMounted(() => {
            addField()

            if (state.params.query) {
                fetchDataList()
            }
        })

        return {
            _,
            state,
            fetchDataList,
            handleTableChange,
            errorMessage,
            queryParams,
            addField,
            removeRowField,
            handleTabChange,
        }
    },
})
</script>
