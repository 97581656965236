<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Detail-Form-Survey"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="id"
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            title="Lihat Hasil Form Survey">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnAnswerView(record)">
                                <i class="fa fa-eye" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
                
            </MdTable>
        </div>

        <!-- answer view -->
        <AnswerView
            v-if="visibleAnswerViewModal"
            v-model:visible="visibleAnswerViewModal"
            v-model:item="visibleAnswerViewItemModal"
            @success="fetchDataList"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import apiClient from '@/services/axios'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FilterDistributor from '@/components/filter/FilterDistributor'
import AnswerView from './AnswerView'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        DownloadExcel,
        FilterDistributor,
        AnswerView,
    },
    setup() {
        const route = useRoute()
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'Kode Survey',
                    dataIndex: 'code',
                },
                {
                    title: 'Nama Survey',
                    dataIndex: 'title',
                },
                {
                    title: 'Tanggal Isi Survey',
                    dataIndex: 'created_date',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint:`/api/formsurvey/${route.params.id}/result`,
            data: [],
            meta: {
                per_page: 30,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                distributor: [],
                page: 1,
                "per-page": 10,
            }),
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle answer view
        const visibleAnswerViewModal = ref(false)
        const visibleAnswerViewItemModal = ref(null)
        const btnAnswerView = (item = null, readOnly = false) => {
            visibleAnswerViewModal.value = true
            visibleAnswerViewItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            fetchDataList,
            handleTableChange,
            errorMessage,
            queryParams,
            visibleAnswerViewModal,
            visibleAnswerViewItemModal,
            btnAnswerView,
        }
    },
})
</script>
