<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <AMonthPicker
                    class="mr-2 mt-2"
                    v-model:value="state.params.month"
                    placeholder="Pilih Bulan"
                    style="width:300px"
                    format="MMMM YYYY"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"/>
                <!-- <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:300px"/> -->
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <!-- bulanan distributor -->
        <div class="row mt-5">
            <!-- Transaksi Sales Bulanan Distributor (MDXL) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Sales Bulanan Distributor (MDXL)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.mdxl_bulanan_distributor"
                                    :params="queryParams()"
                                    namefile="Laporan-RAW-MDXL-Bulanan-Distributor"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.mdxl_bulanan_distributor"
                                :params="queryParams()"
                                :columns="state.columns"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Transaksi Sales Bulanan Distributor (POS) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Sales Bulanan Distributor (POS)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.pos_bulanan_distributor"
                                    :params="queryParams()"
                                    namefile="Laporan-RAW-POS-Bulanan-Distributor"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.pos_bulanan_distributor"
                                :params="queryParams()"
                                :columns="state.columnsPos"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- bulanan gudang distributor -->
        <div class="row mt-5">
            <!-- Transaksi Sales Bulanan Gudang Distributor (MDXL) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Sales Bulanan Gudang Distributor (MDXL)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.mdxl_bulanan_gudang_distributor"
                                    :params="queryParams()"
                                    namefile="Laporan-RAW-MDXL-Bulanan-Gudang-Distributor"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.mdxl_bulanan_gudang_distributor"
                                :params="queryParams()"
                                :columns="state.columnsMdxlMonthly"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Transaksi Sales Bulanan Gudang Distributor (POS) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Sales Bulanan Gudang Distributor (POS)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.pos_bulanan_gudang_distributor"
                                    :params="queryParams()"
                                    namefile="Laporan-RAW-POS-Bulanan-Gudang-Distributor"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.pos_bulanan_gudang_distributor"
                                :params="queryParams()"
                                :columns="state.columnsPosMonthly"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- harian distributor -->
        <div class="row mt-5">
            <!-- Transaksi Sales Harian Distributor (MDXL) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Sales Harian Distributor (MDXL)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.mdxl_harian_distributor"
                                    :params="queryParams()"
                                    namefile="Laporan-RAW-MDXL-Harian-Distributor"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.mdxl_harian_distributor"
                                :params="queryParams()"
                                :columns="state.columns"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Transaksi Sales Harian Distributor (POS) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Sales Harian Distributor (POS)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.pos_harian_distributor"
                                    :params="queryParams()"
                                    namefile="Laporan-RAW-POS-Harian-Distributor"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.pos_harian_distributor"
                                :params="queryParams()"
                                :columns="state.columnsPosDaily"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { defineComponent, reactive, nextTick, onMounted } from 'vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FilterDistributor,
        DownloadExcel,
        TableStandard,
    },
    setup() {
        const state = reactive({
            isFetching: false,
            columns: [
                {
                    title: 'Tanggal',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'QTY ZAK',
                    dataIndex: 'volume_zak',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
                {
                    title: 'Total Raw',
                    dataIndex: 'total_row',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
            ],
            columnsMdxlMonthly: [
                {
                    title: 'Tanggal',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'warehouse_code',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'warehouse_name',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'QTY ZAK',
                    dataIndex: 'volume_zak',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
                {
                    title: 'Total Raw',
                    dataIndex: 'total_row',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
            ],
            columnsPos: [
                {
                    title: 'Tanggal',
                    dataIndex: 'month',
                    customRender: ({ text }) => moment().month(text).format("YYYY-MM"),
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributorCode',
                    customRender: ({ text }) => parseInt(text).toString().padStart(10, "0"),
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributorName',
                },
                {
                    title: 'QTY ZAK',
                    dataIndex: 'totalZak',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
                {
                    title: 'Total Raw',
                    dataIndex: 'rowData',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
            ],
            columnsPosMonthly: [
                {
                    title: 'Tanggal',
                    dataIndex: 'month',
                    customRender: ({ text }) => moment().month(text).format("YYYY-MM"),
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'warehouseCode',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'warehouseName',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributorCode',
                    customRender: ({ text }) => parseInt(text).toString().padStart(10, "0"),
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributorName',
                },
                {
                    title: 'QTY ZAK',
                    dataIndex: 'quantityZak',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
                {
                    title: 'Total Raw',
                    dataIndex: 'rowData',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
            ],
            columnsPosDaily: [
                {
                    title: 'Tanggal',
                    dataIndex: 'date',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributorCode',
                    customRender: ({ text }) => parseInt(text).toString().padStart(10, "0"),
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributorName',
                },
                {
                    title: 'QTY ZAK',
                    dataIndex: 'totalZak',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
                {
                    title: 'Total Raw',
                    dataIndex: 'rowData',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
            ],
            params: extractQueryParams({
                q: '',
                distributor: [],
                month: moment(new Date()).startOf('month').format('YYYY-MM'),
            }),
            url: {
                mdxl_bulanan_distributor: '/api/report/metabase-v2/transaksi-bulanan-distributor',
                mdxl_bulanan_gudang_distributor: '/api/report/metabase-v2/transaksi-bulanan-gudang-distributor',
                mdxl_harian_distributor: '/api/report/metabase-v2/transaksi-harian-distributor',
                pos_bulanan_gudang_distributor: '/api/report/metabase-v2/transaksi-bulanan-gudang-distributor-pos',
                pos_bulanan_distributor: '/api/report/metabase-v2/transaksi-bulanan-distributor-pos',
                pos_harian_distributor: '/api/report/metabase-v2/transaksi-harian-distributor-pos',
            },
        })

        const queryParams = () => {
            let params = state.params

            if (state.params.month) {
                params.start_date = moment(state.params.month).startOf('month').format('YYYY-MM-DD')
                params.end_date = moment(state.params.month).endOf('month').format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        const btnFetchData = async () => {
            state.isFetching = true
            await nextTick()
            state.isFetching = false
        }

        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            queryParams,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        margin-top: 0;
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
    }
}
</style>
