<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AMonthPicker
                    v-model:value="state.params.month"
                    :allow-clear="false"
                    placeholder="Pilih Bulan"
                    style="width:100%;"
                    format="MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.provinsi"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.provinsi"
                    v-model:area="state.area"
                    v-model:value="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.provinsi"
                    v-model:area="state.area"
                    v-model:kabupaten="state.kabupaten"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterBrand
                    style="width:100%;"
                    v-model:value="state.params.brand"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint"
                            :params="queryParams()"
                            namefile="Laporan-Toko-Aktif-CA-LT"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)"
                                :disabled="record.is_action_disable">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'
import {
    hasRoles,
} from '@/helpers'
import {
    FormatNumber,
} from '@/helpers/utils'

export default defineComponent({
    components: {
        FilterRegional,
        FilterDistributor,
        FilterProvince,
        FilterArea,
        FilterKabupatenKota,
        FilterBrand,
        FilterToko,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'No',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal Transaksi',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'toko_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'toko_name',
                },
                {
                    title: 'Alamat Toko',
                    dataIndex: 'toko_address',
                },
                {
                    title: 'Kabupaten Toko',
                    dataIndex: 'toko_kab',
                },
                {
                    title: 'Provinsi Toko',
                    dataIndex: 'toko_prov',
                },
                {
                    title: 'Regional Toko',
                    dataIndex: 'toko_reg',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'toko_area',
                },
                {
                    title: 'Brands ',
                    dataIndex: 'brand_name',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'product_code',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'product_name',
                },
                {
                    title: 'Harga',
                    dataIndex: 'price',
                    customRender: ({ text }) => FormatNumber(text),
                },
                {
                    title: 'Zak Quantity',
                    dataIndex: 'quantity_zak',
                },
                {
                    title: 'UOM 1',
                    dataIndex: 'uom1',
                },
                {
                    title: 'TON Quantity',
                    dataIndex: 'quantity_ton',
                },
                {
                    title: 'UOM 2',
                    dataIndex: 'uom2',
                },
                {
                    title: 'No Transaksi',
                    dataIndex: 'ts_id',
                },

                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributor_code',
                },
                    {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor_name',
                },
                {
                    title: 'Kode LT',
                    dataIndex: 'lt_code',
                },
                {
                    title: 'Nama LT',
                    dataIndex: 'lt_name',
                },
                {
                    title: 'Regional LT',
                    dataIndex: 'lt_reg',
                },
                {
                    title: 'Provinsi LT',
                    dataIndex: 'lt_prov',
                },
                {
                    title: 'Area LT',
                    dataIndex: 'lt_area',
                },
                {
                    title: 'Kabupaten LT',
                    dataIndex: 'lt_kab',
                },
                {
                    title: 'Cluster',
                    dataIndex: 'cluster_name',
                },
                {
                    title: 'SSM',
                    dataIndex: 'user_ssm',
                    customRender: ({ text }) => text || '-',
                },
                {
                    title: 'ASM',
                    dataIndex: 'user_asm',
                    customRender: ({ text }) => text || '-',
                },
                {
                    title: 'TSO',
                    dataIndex: 'user_tso',
                    customRender: ({ text }) => text || '-',
                },
            ],
            endpoint: '/api/report/laporan-lt-ca',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                area: [],
                kabupaten: [],
                distributor: [],
                brand: [],
                toko: [],
                month: moment().format('YYYY-MM'),
                page: 1,
                "per-page": 10,
            }),
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.month) {
                state.params.month = moment(state.params.month).format('YYYY-MM')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            errorMessage,
            fetchDataList,
            handleTableChange,
            queryParams,
            // only variable role
            hasRoles,
        }
    },
})
</script>
