<template>
    <a-modal
        :visible="visible"
        title="Unggah List Toko - Master User Salesman"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true">

        <template #footer>
            <AButton
                @click="handleModalCancel">Batal</AButton>
            <AButton
                key="submit"
                type="primary"
                :loading="state.loading"
                @click="handleSubmitOk">Submit</AButton>
        </template>

        <AForm
            ref="formRef"
            class="myform"
            :model="state.form"
            :rules="state.rules"
            @submit.prevent="btnUploadFile()"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- upload documen -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Unggah Dokumen"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        has-feedback>
                        <a-input
                            type="file"
                            required
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="onFileChange"
                        ></a-input>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row mb-4">
                <ACol :sm="4"/>
                <a-col :sm="20">
                    <ASpace>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="state.form.loading">Upload</a-button>
                        <AButton
                            @click="btnDownloadTemplate()"
                            :loading="state.loadingTemplate">Unduh Template</AButton>
                    </ASpace>
                </a-col>
            </a-row>
        </AForm>

        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>
            
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="state.data.length"
                        title="Download excel">
                        <AButton
                            type="primary"
                            @click="btnDownload()"
                            :loading="state.loadingDownloadDistributor">
                            <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.loadingDownloadDistributor"></i>
                            <span v-else>Downloading ...</span>
                        </AButton>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                row-key="code"
                :row-selection="rowSelection"
                size="small"
                :loading="state.loading">
                <template #tags="{ record }">
                    <a-tag :color="record.errors.length == 0 ? 'green' : 'volcano'">
                    <template #icon>
                        <template v-if="record.errors.length == 0"> <CheckCircleOutlined /> Ya </template>
                        <template v-else>
                            <APopover trigger="click">
                                <template #content>
                                    <AList size="small" :data-source="record.errors">
                                        <template #renderItem="{ item }">
                                            <AListItem>{{ item }}</AListItem>
                                        </template>
                                    </AList>
                                </template>
                                <CloseCircleOutlined /> Tidak
                            </APopover>
                        </template>
                    </template>
                    </a-tag>
                </template>
            </MdTable>
        </div>

        <AModal
            v-model:visible="state.result.isShow"
            title="Result"
            @cancel="handleModalCancel"
            @ok="handleModalCancel">
            <template #footer>
                <AButton key="back" type="primary" @click="handleShowCancel">OK</AButton>
            </template>
            <AAlert type="success" :message="`Sukses: ${state.result.valid}`"></AAlert>
            <AAlert type="error">
                <template #message>
                    {{ `Gagal: ${state.result.failed}` }}
                    <a-tree :tree-data="state.result.data" default-expand-all/>
                </template>
            </AAlert>
        </AModal>

    </a-modal>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import apiClient from '@/services/axios'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_DISTRIBUTOR,
    ROLE_TSO,
    ROLE_TIM_CRM,
    ROLE_HELPDESK,
} from '@/helpers'

export default defineComponent({
    components: {
        CheckCircleOutlined,
        CloseCircleOutlined,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['btnUploadFileConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()

        const formRef = ref();

        const state = reactive({
            columns: [
                {
                    title: 'Valid?',
                    dataIndex: 'valid',
                    slots: {
                        customRender: 'tags',
                    },
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Kota/Kabupaten',
                    dataIndex: 'kabupaten',
                },
                {
                    title: 'ID Salesman',
                    dataIndex: 'salesman_id',
                },
                {
                    title: 'ID TSO',
                    dataIndex: 'tso_id',
                },
                {
                    title: 'Status',
                    dataIndex: 'action',
                },
            ],
            endpoint: {
                current: `/api/users-salesman/customers/upload`,
                current_distributor: `/api/users-salesman/by-distributor/customers/upload`,
                template: `/api/users-salesman/customers/${props.item.id}/unduh-template`,
                template_distributor: `/api/users-salesman/by-distributor/customers/${props.item.id}/unduh-template`,
            },
            data: [],
            loading: false,
            loadingDownloadDistributor: false,
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            selectedRowKeys: [],
            selectedRows: [],
            result: {
                isShow: false,
                data: [],
                valid: 0,
                failed: 0,
            },
            form: {
                loading: false,
                files: null,
                file: null,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            state.form.file = files[0]
        }

        const rowSelection = computed(() => {
            return {
                selectedRowKeys: state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    state.selectedRowKeys = selectedRowKeys
                    state.selectedRows = selectedRows
                },
                getCheckboxProps: record => ({
                    disabled: !record.valid,
                }),
            }
        })

        const btnUploadFile = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => { });
            
            state.form.loading = true

            const form_data = new FormData()

            form_data.append('file', state.form.file)

            apiClient
                .post(
                    hasRoles([ROLE_DISTRIBUTOR]) ? state.endpoint.current_distributor : state.endpoint.current,
                    form_data,
                )
                .then(({ data }) => {
                    data.forEach((item, index) => {
                        item.code = index
                    })

                    state.data = data
                })
                .catch(async error => {
                    errorMessage.value = null

                    if (error.response && error.response.status !== 500) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    if (error.response && error.response.status === 500) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    message.error('Gagal mengungah!')
                })
                .finally(() => {
                    state.form.loading = false
                })
        }

        const handleSubmitOk = async () => {
            errorMessage.value = null
            state.loading = true

            apiClient
                .post(
                    hasRoles([ROLE_DISTRIBUTOR]) ? state.endpoint.current_distributor : state.endpoint.current,
                    { simpan: state.selectedRows },
                )
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length === 0) {
                        message.warning('Tidak ada data!, pastikan sudah memilih datanya')
                        return
                    }

                    state.result.isShow = true
                    state.result.data = []
                    state.result.valid = 0
                    state.result.failed = 0

                    data.forEach((item, idx) => {
                        if (item.valid) {
                            state.result.valid++
                        } else {
                            state.result.failed++
                            const branch = {}
                            state.result.data.push(branch)
                            branch.title = 'Baris ' + (idx + 1)
                            branch.key = idx
                            branch.children = []
                            if (item.errors.length) {
                                item.errors.forEach((v, i) => {
                                    branch.children.push({
                                        title: `Kolom ${v.field} : ${v.message}`,
                                        key: `${idx}-${i}`,
                                    })
                                })
                            }
                        }
                    })
                    
                    emit('success', data)
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading = false
                    state.selectedRowKeys = []
                    state.selectedRows = []
                })
        }

        const btnDownloadTemplate = async () => {
            state.loadingTemplate = true
            
            let params = {
                params: {
                    _export: 'xls',
                },
                responseType: 'blob',
                headers: {},
            }

            apiClient.post(hasRoles([ROLE_DISTRIBUTOR]) ? state.endpoint.template_distributor : state.endpoint.template, null, params)
                .then(response => {
                    if (response === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `List-Toko-Master-User-Salesman_${moment().format('DDMMYY_HHmm')}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loadingTemplate = false
                })
        }

        const btnDownload = () => {
            state.loadingDownloadDistributor = true

            const form_data = new FormData()

            form_data.append('file', state.form.file)

            apiClient
                .post(
                    hasRoles([ROLE_DISTRIBUTOR]) ? state.endpoint.current_distributor : state.endpoint.current,
                    form_data,
                    {
                        params: {
                            _export: 'xls',
                        },
                        responseType: 'blob',
                        headers: {},
                    },
                )
                .then(response => {
                    if (response === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `List-Toko-Master-User-Salesman-${moment().format('DDMMYY_HHmm')}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loadingDownloadDistributor = false
                })
        }

        const handleShowCancel = () => {
            state.result.isShow = false
            handleModalCancel()
        }

        onMounted(() => {
            // only column in tables
            if (!hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])) {
                state.columns = state.columns.filter(item => {
                    if (!_.includes(['kabupaten'], item.dataIndex)) {
                        return item
                    }
                })
            }
        })

        return {
            errorMessage,
            state,
            formRef,
            btnUploadFile,
            handleSubmitOk,
            handleModalCancel,
            onFileChange,
            rowSelection,
            btnDownloadTemplate,
            handleShowCancel,
            btnDownload,
            // only variable roles
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_DISTRIBUTOR,
            ROLE_TSO,
            ROLE_TIM_CRM,
            ROLE_HELPDESK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
