<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"/>

    <!-- filter -->
    <ARow :gutter="[8, 8]">
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <ADatePicker
          style="width:100%;"
          :disabled-date="disabledStartDate"
          v-model:value="state.params.start_date"
          placeholder=" Tanggal Awal"
          format="DD MMMM YYYY"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <ADatePicker
          style="width:100%;"
          :disabled-date="disabledEndDate"
          v-model:value="state.params.end_date"
          placeholder=" Tanggal Akhir"
          format="DD MMMM YYYY"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-regional
          style="width:100%;"
          v-model:value="state.params.region" />
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-province
          style="width:100%;"
          v-model:value="state.params.provinsi"
          v-model:region="state.params.region" />
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-area
          style="width:100%;"
          v-model:value="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:region="state.params.region" />
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-kabupaten-kota
          style="width:100%;"
          v-model:value="state.params.kabupaten"
          v-model:kabupaten="state.params.kabupaten"
          v-model:area="state.params.area"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-products
          style="width:100%;"
          v-model:value="state.params.product"
          v-model:product="state.params.product" />
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-distributor
          style="width:100%;"
          v-model:value="state.params.distributor"
          v-model:distributor="state.params.distributor"
          v-model:region="state.params.region"
          v-model:area="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:kabupaten="state.params.kabupaten"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-segment
          style="width:100%;"
          :mode="null"
          placeholder="Pilih Tipe Customer"
          v-model:value="state.params.segment"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-input-search
          style="width:100%;"
          v-model:value="state.params.q"
          placeholder="Cari ..."/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-button
          type="primary"
          title="cari"
          @click="fetchData"
          :loading="state.isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </ACol>
    </ARow>
    
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <DownloadExcel
          :url="state.endpoint"
          :params="queryParams()"
          namefile="transaksi-toko-harian"/>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
            <div class="table-responsive">
              <md-table
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                row-key="id"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: state.meta.total,
                  pageSize: state.meta.per_page,
                  current: state.meta.page,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #no="{ index }">
                  <span>
                    {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                  </span>
                </template>

                <template #nilai="{ text }">
                  <span>{{ text ? new Intl.NumberFormat('id-ID').format(text) : 0 }}</span>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterSegment from '@/components/filter/FilterSegment'
import FilterProducts from '@/components/filter/FilterProduct'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
  components: {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    FilterProducts,
    FilterSegment,
    DownloadExcel,
  },
  setup() {
    const errorMessage = ref(null)
    const state = reactive({
      columns: [
        {
          title: 'NO',
          slots: { customRender: 'no' },
        },
        {
          title: 'Tanggal Transaksi',
          dataIndex: 'tgl_trx',
        },
        {
          title: 'ID Toko',
          dataIndex: 'vendor_code',
        },
        {
          title: 'Nama Toko',
          dataIndex: 'nama_toko',
        },
        {
          title: 'Regional Toko',
          dataIndex: 'reg_toko',
        },
        {
          title: 'Provinsi Toko',
          dataIndex: 'prov_toko',
        },
        {
          title: 'Area Toko',
          dataIndex: 'area_toko',
        },
        {
          title: 'Kabupaten/Kota Toko',
          dataIndex: 'kab_toko',
        },
        {
          title: 'No Transaksi',
          dataIndex: 'no_so',
        },
        {
          title: 'Kode Distributor',
          dataIndex: 'distri_code',
        },
        {
          title: 'Nama Distributor',
          dataIndex: 'distri_name',
        },
        {
          title: 'Kode Produk',
          dataIndex: 'kode_produk',
        },
        {
          title: 'Nama Produk',
          dataIndex: 'produk_name',
        },
        {
          title: 'Kode MD Royalty',
          dataIndex: 'kode_md_royalty',
        },
        {
          title: 'Nama MD Royalty',
          dataIndex: 'nama_md_royalty',
        },
        {
          title: 'Brands',
          dataIndex: 'brand',
        },
        {
          title: 'UOM ',
          dataIndex: 'uom',
        },
        {
          title: 'Zak Quantity ',
          dataIndex: 'zak_qty',
        },
        {
          title: 'TON Quantity ',
          dataIndex: 'ton_qty',
        },
        {
          title: 'Harga ',
          dataIndex: 'price',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Total Harga ',
          dataIndex: 'total_price',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Kode Gudang ',
          dataIndex: 'kode_gudang',
        },
        {
          title: 'Nama Gudang ',
          dataIndex: 'nama_gudang',
        },
        {
          title: 'Regional Gudang',
          dataIndex: 'reg_gudang',
        },
        {
          title: 'Provinsi Gudang',
          dataIndex: 'prov_gudang',
        },
        {
          title: 'Area Gudang',
          dataIndex: 'area_gudang',
        },
        {
          title: 'Kota / Kabupaten Gudang',
          dataIndex: 'kab_gudang',
        },
        {
          title: 'Tipe Customer',
          dataIndex: 'type_name',
        },
        {
          title: 'Cluster',
          dataIndex: 'cluster_name',
        },
        {
          title: 'SSM',
          dataIndex: 'user_ssm',
          title_xls: 'SSM',
        },
        {
          title: 'ASM',
          dataIndex: 'user_sm',
          title_xls: 'ASM',
        },
        {
          title: 'TSO',
          dataIndex: 'user_am',
          title_xls: 'TSO',
        },
        {
          title: 'Salesman',
          dataIndex: 'salesman_name',
        },
      ],
      endpoint: '/api/report/transaksi-toko-harian',
      data: [],
      isFetching: false,
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
      params: extractQueryParams({
        q: '',
        region: [],
        area: [],
        provinsi: [],
        kabupaten: [],
        product: [],
        type: [],
        distributor: [],
        start_date: moment(new Date()).startOf('month'),
        end_date: moment(new Date()).subtract(1, 'days'),
        page: 1,
        'per-page': 10,
      }),
    })

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      
      fetchData()
    }

    const disabledStartDate = (current) => {
      return current && current > moment(state.params.end_date)
    }

    const disabledEndDate = (current) => {
      return current && moment(state.params.start_date) >= current
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.start_date) {
          state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
      }

      if (state.params.end_date) {
          state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = async () => {
      state.isFetching = true
      await apiClient
        .get(state.endpoint, {
          params: queryParams(),
        })
        .then(({ data }) => {
          const { items, _meta } = data
          
          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    
    onMounted(() => {
      fetchData()
    })

    return {
      errorMessage,
      fetchData,
      state,
      queryParams,
      handleTableChange,
      disabledStartDate,
      disabledEndDate,
    }
  },
})
</script>
