<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol
                v-can:hide.distributor
                :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol
                v-can:hide.distributor
                :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%"
                    v-model:region="state.params.region"
                    v-model:value="state.params.provinsi"/>
            </ACol>
            <ACol
                v-can:hide.distributor
                :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:region="state.params.region"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterLt
                    style="width:100%"
                    v-model:distributor="state.params.distributor"
                    v-model:value="state.params.lt"/>
            </ACol>
            <ACol
                v-can:hide.distributor
                :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%"
                    v-model:area="state.params.area"
                    v-model:value="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    :options="state.statusLists"
                    allow-clear
                    style="width:100%;"
                    placeholder="Pilih Status"
                    v-model:value="state.params.status"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:100%"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-has-access="['customer-lt.destroy']"
                        title="Hapus Mapping">
                        <AButton
                            type="danger"
                            @click="btnDelete">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Upload Dokumen">
                        <AButton
                            type="primary"
                            @click="btnUpload">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                row-key="id"
                :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- upload -->
        <Upload
            v-if="visibleUploadModal"
            v-model:visible="visibleUploadModal"
            v-model:item="visibleUploadItemModal"
            @success="fetchDataList"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, h } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterLt from '@/components/filter/FilterLt'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from './Form'
import Upload from './Upload'
import useUserInfo from '@/composables/useUserInfo'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FilterRegional,
        FilterProvince,
        FilterArea,
        FilterKabupatenKota,
        FilterLt,
        DownloadExcel,
        Form,
        Upload,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal Didaftarkan',
                    dataIndex: 'registration_at',
                    width: 100,
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'name',
                    width: 130,
                },
                {
                    title: 'Kode Distributor Toko',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor Toko',
                    dataIndex: 'distributor_name',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                    width: 200,
                },
                {
                    title: 'Nama Pemilik Toko',
                    dataIndex: 'owner',
                    width: 150,
                },
                {
                    title: 'Region Toko',
                    dataIndex: 'region',
                    width: 100,
                },

                {
                    title: 'Provinsi Toko',
                    dataIndex: 'provinsi',
                    width: 120,
                },

                {
                    title: 'Area Toko',
                    dataIndex: 'area',
                    width: 100,
                },

                {
                    title: 'Kabupaten Toko',
                    dataIndex: 'kabupaten',
                    width: 200,
                },

                {
                    title: 'Kecamatan Toko',
                    dataIndex: 'kecamatan',
                },
                {
                    title: 'Kelurahan / Desa Toko',
                    dataIndex: 'desa',
                },
                {
                    title: 'Status Toko',
                    title_xls: 'status toko',
                    dataIndex: 'status_text',
                    width: 90,
                },
                {
                    title: 'Status Approval',
                    dataIndex: 'status',
                },
                {
                    title: 'Kode Large Toko',
                    dataIndex: 'lt_code',
                    width: 100,
                },
                {
                    title: 'Nama Large Toko',
                    dataIndex: 'lt_name',
                    width: 100,
                },
                {
                    title: 'Kode Distributor Large Toko',
                    dataIndex: 'distributor_lt_code',
                    width: 100,
                },
                {
                    title: 'Nama Distributor Large Toko',
                    dataIndex: 'distributor_lt_name',
                    width: 100,
                },
                {
                    title: 'kelengkapan dokumen',
                    title_xls: 'kelengkapan dokumen',
                    dataIndex: 'kelengkapan_dokumen',
                    hidden: true,
                },
            ],
            endpoint: {
                current: '/api/customer-lt',
                unmapping: '/api/customer-lt/unmapping',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                area: [],
                provinsi: [],
                kabupaten: [],
                distributor: [],
                lt: [],
                status: null,
                page: 1,
                "per-page": 10,
            }),
            selectedRows: [],
            selectedRowKeys: [],
            statusLists: [
                { value: 0, label: 'New' },
                { value: 1, label: 'Approve TSO' },
                { value: 2, label: 'Approve ASM' },
                { value: 6, label: 'Hapus' },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // btn delete
        const btnDelete = (id) => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Unmapping',
                content: 'Apakah Anda ingin hapus unmapping toko tersebut?',
                onOk() {
                    apiClient
                        .post(state.endpoint.unmapping, {
                            data: state.selectedRowKeys,
                        })
                        .then(({ data }) => {
                            fetchDataList()
                            state.selectedRowKeys = []

                            let messages = []
                            data.forEach((item) => {
                                if (!item.success) {
                                    messages.push(h('p', `- ${item.errors}`))
                                }
                            })

                            if (messages.length) {
                                message.success('Berhasil dihapus, beberapa ada yang gagal.')

                                Modal.info({
                                    title: 'Terdapat ummapping yang gagal di hapus!',
                                    content: h('div', {}, messages),
                                    width: 700,
                                    onOk() {
                                    },
                                })
                            } else {
                                message.success('Berhasil dihapus.')
                            }
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle mapping list
        const visibleUploadModal = ref(false);
        const visibleUploadItemModal = ref(null);

        const btnUpload = (item = null) => {
            visibleUploadModal.value = true
            visibleUploadItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            const { isDistributor, vendor_id, regions, areas } = useUserInfo()
            if (isDistributor.value) {
                state.params.distributor.push(vendor_id.value)
                regions.value.forEach(item => {
                    // state.params.region.push(item.id)
                })
                areas.value.forEach(item => {
                    // state.params.area.push(item.id)
                })
            }

            fetchDataList()
        })

        return {
            state,
            errorMessage,
            fetchDataList,
            handleTableChange,
            onSelectChange,
            queryParams,
            btnForm, // create or update
            visibleFormModal,
            visibleFormItemModal,
            btnUpload,
            visibleUploadModal,
            visibleUploadItemModal,
            btnDelete,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>
