<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"/>

    <!-- filter -->
    <ARow :gutter="[8, 8]">
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <ADatePicker
          style="width:100%;"
          v-model:value="state.params.start_date"
          placeholder="Dari Tanggal"
          :disabled-date="disabledStartDate"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <ADatePicker
          style="width:100%;"
          v-model:value="state.params.end_date"
          placeholder="Sampai Tanggal"
          :disabled-date="disabledEndDate"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-regional
          style="width:100%;"
          v-model:value="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-province
          style="width:100%;"
          v-model:value="state.params.provinsi"
          v-model:region="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-area
          style="width:100%;"
          v-model:value="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:region="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-kabupaten-kota
          style="width:100%;"
          v-model:value="state.params.kabupaten"
          v-model:area="state.params.area"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-distributor
          style="width:100%;"
          v-model:value="state.params.distributor"
          v-model:region="state.params.region"
          v-model:area="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:kabupaten="state.params.kabupaten"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-gudang
          style="width:100%;"
          v-model:value="state.params.gudang"
          v-model:region="state.params.region"
          v-model:area="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:distributor="state.params.distributor"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <ASpace>
          <AButton
            type="primary"
            title="cari"
            @click="fetchData"
            :loading="state.isFetching">
            <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
            <span v-else>Memuat Data ...</span>
          </AButton>
          <ATooltip title="Download Excel">
            <DownloadExcel
              :url="state.endpoint.excel"
              :params="queryParams()"
              namefile="Performance-Gudang"
              @errors="errorMessage"/>
          </ATooltip>
        </ASpace>
      </ACol>
    </ARow>

    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <!-- <e-chart title="Performance Gudang" /> -->
              <e-chart title="Performance Gudang" vif="!isFetching" :response="state.data" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <!-- <e-chart title="Transaksi Gudang" /> -->
              <t-chart title="Transaksi Gudang" vif="!isFetching"  :response="state.data"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineAsyncComponent,
  defineComponent,
  ref,
  reactive,
  toRefs,
} from 'vue'
import Loading from '@/components/Loading'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterGudang from '@/components/filter/FilterGudang'
import FilterDistributor from '@/components/filter/FilterDistributor'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
  components: {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    FilterGudang,
    DownloadExcel,
    EChart: defineAsyncComponent({
      loader: () => import('./EChart.vue' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
    }),
    TChart: defineAsyncComponent({
      loader: () => import('./TChart.vue' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
    }),
  },
  setup() {
    const errorMessage = ref(null)
    const state = reactive({
      endpoint: {
        current: '/api/report/grafik-gudang',
        excel: '/api/report/performance-gudang',
      },
      data: [],
      isFetching: false,
      params: extractQueryParams({
        q: '',
        region: [],
        area: [],
        provinsi: [],
        kabupaten: [],
        gudang: [],
        distributor: [],
        start_date: moment(new Date()).startOf('month'),
        end_date: moment(new Date()),
      }),
    })

    const disabledStartDate = (current) => {
      return current && current >= moment(state.params.end_date)
    }

    const disabledEndDate = (current) => {
      return current && moment(state.params.start_date) >= current
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.start_date) {
          state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
      }

      if (state.params.end_date) {
          state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = () => {
      state.isFetching = true
      apiClient
        .get('api/report/grafik-gudang', {
          params: queryParams(),
        })
        .then(({ data }) => {
          state.data = data
        })
        .catch(error => {
          this.errored = true
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    return {
      state,
      errorMessage,
      queryParams,
      fetchData,
      disabledStartDate,
      disabledEndDate,
    }
  },
})
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
