<template>
  <div>
    <!-- <pre>{{ filters }}</pre> -->
    <!-- filter -->
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="m-2" v-model:value="regionals" v-can:hide.distributor />
        <filter-province
          class="m-2"
          v-model:value="province"
          v-model:region="regionals"
          v-can:hide.distributor
        />
        <filter-area
          class="m-2"
          v-model:value="areas"
          v-model:provinsi="province"
          v-model:region="regionals"
          v-can:hide.distributor
        />
        <filter-kabupaten-kota
          class="m-2"
          v-model:value="kabupaten"
          v-model:area="areas"
          v-model:region="regionals"
          v-model:provinsi="province"
          v-can:hide.distributor
        />
      </div>
      <div class="col-md-12">
        <filter-distributor
          class="m-2"
          v-model:value="distributors"
          v-model:provinsi="province"
          v-model:area="areas"
          v-model:region="regionals"
          v-model:kabupaten="kabupaten"
          v-can:hide.distributor
        ></filter-distributor>
        <a-select
          :options="[
            { value: 1, label: 'Gudang Fisik' },
            { value: 2, label: 'Gudang Virtual' },
            { value: 3, label: 'Gudang Proyek' },
            { value: 4, label: 'Gudang Direct Address' },
          ]"
          :mode="null"
          allow-clear
          class="m-2"
          style="width: 300px;"
          placeholder="Pilih Jenis Gudang"
          v-model:value="type"
        ></a-select>
        <a-select
          :options="[
            { value: null, label: 'Semua' },
            { value: 1, label: 'Aktif' },
            { value: 0, label: 'Tidak Aktif' },
          ]"
          :mode="null"
          class="m-2"
          allow-clear
          style="width: 300px;"
          placeholder="Pilih Status Gudang"
          v-model:value="status"
        >
        </a-select>
        <a-input-search class="m-2" style="width: 300px" v-model:value="q" placeholder="Cari ..." />
        <a-button class="m-2" type="primary" title="cari" :loading="isFetching" @click="search">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>

      <!-- end filter -->
    </div>
    <!-- tombol -->
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <AButton
          v-if="hasRoles([ROLE_ADMIN_BK, ROLE_ADMIN_IO])"
          title="Unggah Kapasitas Gudang"
          type="primary"
          @click="btnUpload">
          <i class="fa fa-upload" aria-hidden="true"></i>
        </AButton>
        <a-button
          title="Aktif Gudang"
          type="primary"
          @click="showConfirm"
          v-if="hasRoles([ROLE_ADMIN_BK])"
          class="ml-2"
        >
          <i class="fa fa-check" aria-hidden="true"></i>
        </a-button>
        <a-modal
          title="Aktif Gudang"
          v-model:visible="modal3Visible"
          @ok="setActive"
          :confirm-loading="loading"
        >
          <p v-if="selectedRowKeys.length === 0">Checklist gudang yang akan di aktifkan</p>
          <p v-else>Anda yakin ingin aktfikan ({{ selectedRowKeys.length }}) gudang?</p>
        </a-modal>

        <span>
          <a-button
            type="danger"
            stylea="width: 200px; background-color: #752727; border: none;"
            class="ml-2"
            @click="setModal1Visible(true)"
            title="NonAktifkan Gudang"
            v-has-access="['warehouses.destroy']"
            v-if="showNonaktifBtn"
            ><i class="fa fa-ban" aria-hidden="true"></i>
          </a-button>
          <a-button
            type="danger"
            stylea="width: 200px; background-color: #752727; border: none;"
            class="ml-2"
            title="NonAktifkan Gudang"
            v-has-access="['warehouses.destroy']"
            disabled
            v-else
            ><i class="fa fa-ban" aria-hidden="true"></i>
          </a-button>
        </span>
        <a-modal
          title="Informasi"
          v-model:visible="modal1Visible"
          @ok="nonaktifkan"
          :confirm-loading="loading"
        >
          <p v-if="selectedRowKeys.length === 0">Checklist gudang yang akan di non aktifkan</p>
          <p v-else>Anda yakin ingin non aktfikan ({{ selectedRowKeys.length }}) gudang?</p>
        </a-modal>
        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          class="ml-2 mr-2"
          v-has-access="['warehouses.store']"
          title=" Tambah Gudang"
          @click="
            () => {
              formState = {}
              formState.status_approval = 0
              formState.type = 1
              modal2Visible = true
              formState.title_state = 'Tambah Gudang Baru'
              formState.state = 'new'
              formState.isNew = true
              formState.isEdit = false
              formState.isView = true
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          :loading="isDownloading"
          @click="fetchXlsx"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <!-- tabel -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <div class="table-responsive text-nowrap">
              <md-table
                :columns="columns"
                :data-source="data"
                row-key="id"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>
                <template #progress="{ text: bar }">
                  <div class="progress">
                    <div
                      :class="['progress-bar', bar.color]"
                      :style="{ width: bar.value + '%' }"
                      role="progressbar"
                    ></div>
                  </div>
                </template>
                <template #value="{ text }">
                  <span class="font-weight-bold">{{ text }}</span>
                </template>
                <template #id="{ text }">
                  <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                </template>
                <template #total="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #tax="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #shipping="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #status="{ text}">
                  <a href="javascript: void(0);">
                    <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                    <a-tag v-else color="grey">Tidak Aktif</a-tag>
                  </a>
                </template>
                <template #action="{record}">
                  <span>
                    <a-tooltip title="Lihat Gudang">
                      <a
                        href="javascript: void(0);"
                        v-has-access="['warehouses.show']"
                        class="btn btn-sm btn-light"
                        @click="viewDetail(record)"
                      >
                        <i class="fe fe-eye" />
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Edit Gudang">
                      <a
                        href="javascript: void(0);"
                        v-has-access="['warehouses.update']"
                        class="btn btn-sm btn-light"
                        @click="
                          () => {
                            modal2Visible = true
                            formState.title_state = 'Edit Gudang'
                            formState.isEdit = true
                            formState.isView = false
                            formState.isNew = false
                            formState.id = record.id
                            formState.record = record
                            formState.readOnly = false
                          }
                        "
                      >
                        <small>
                          <i class="fe fe-edit" />
                        </small>
                      </a>
                    </a-tooltip>
                  </span>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <a-modal
      v-model:visible="modal2Visible"
      :title="formState.title_state"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
      @ok="modal2Visible = false"
      key="modal-form"
      :footer="null"
    >
      <m-modal @finish="onFinish"></m-modal>
    </a-modal>
    <a-modal
      title="Reason"
      v-model:visible="modal1Visible"
      @ok="nonaktifkan"
      :confirm-loading="loading"
    >
      <p v-if="selectedRowKeys.length === 0">Checklist gudang yang akan di nonaktifkan</p>
      <div v-else>
        <p>Alasan ingin nonaktifkan gudang ini?</p>
        <a-input v-model:value="reason" class="mt-3 mb-1" placeholder="Alasan" />
        <a-alert v-if="errorMessage" :message="errorMessage" type="error" />
      </div>
    </a-modal>

    <!-- <a-modal
      :title="formState.title_state"
      v-model:visible="modal3Visible"
      @ok="simpan"
      :confirm-loading="loading"
    >
    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Status Gudang"
          label-align="left"
          :label-col="{ sm: { span: 6 } }"
          :wrapper-col="{ sm: { span: 24 - 6 } }"
        >
         <a-radio-group :disabled="formState.readOnly" v-model:value="formState.active" required>
              <a-radio :value="true">Aktif</a-radio>
              <a-radio :value="false">Tidak Aktif</a-radio>
            </a-radio-group>
          </a-form-item>
      </a-col>
      </a-row>
    </a-modal> -->

    <!-- upload -->
    <Upload
      v-if="visibleUploadModal"
      v-model:visible="visibleUploadModal"
      @success="search"/>
  </div>
</template>

<script>
import { ref, onMounted, provide, reactive, toRefs } from 'vue'
import apiClient from '@/services/axios'

import { useRoute, useRouter } from 'vue-router'

import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterArea from '@/components/filter/FilterArea'
import FilterDistributor from '@/components/filter/FilterDistributor'
import MModal from './modal'
import Upload from './Upload'

import { Modal, message } from 'ant-design-vue'

import acolumns from './columns.js'
import qs from 'qs'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'
import {
  ROLE_ADMIN_BK,
  ROLE_ADMIN_IO,
  hasRoles,
} from '@/helpers/index.js'

export default {
  components: {
    FilterRegional,
    FilterProvince,
    MModal,
    FilterKabupatenKota,
    FilterArea,
    FilterDistributor,
    Upload,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modal3Visible = ref(false)
    const q = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const type = ref(null)
    const status = ref([])
    const current1 = ref(1)
    const isFetching = ref(false)
    const active = ref([])
    const showNonaktifBtn = ref(true)

    columns.value = acolumns
    status.value = 1

    const { isDistributor, vendor_id } = useUserInfo()
    const formState = ref({ title_state: '', code: null })

    const errorMessage = ref(null)

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      q.value = ''
    }

    const handleReset = clearFilters => {
      clearFilters()
      q.value = ''
    }
    const setModal1Visible = visible => {
      if (visible) {
        if (state.selectedRowKeys.length === 0) {
          Modal.warning({
            content: 'Checklist gudang yang akan di non aktifkan!',
          })
          return
        }
      }
      modal1Visible.value = visible
    }

    // pagination
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({
      totalCount: 0,
    })
    const page = ref(1)

    // filters
    const filters = reactive({
      province: [],
      regionals: [],
      areas: [],
      kabupaten: [],
      products: [],
      brands: [],
      distributors: [],
      q: '',
      status: status,
      active: [],
      reason: '',
    })

    if (isDistributor.value) {
      filters.distributors.push(vendor_id.value)
    }

    const params = ref({})
    onMounted(() => {
      const { distributor } = route.query
      if (distributor) {
        if (!filters.distributors.includes(Number(distributor)))
          filters.distributors.push(Number(distributor))
      }
      fetchData()
    })
    const fetchData = () => {
      isFetching.value = true
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: filters.regionals,
        area: filters.areas,
        provinsi: filters.province,
        kabupaten: filters.kabupaten,
        produk: filters.products,
        brand: filters.brands,
        distributor: filters.distributors,
        q: filters.q,
        type: type.value,
        status: filters.status,
        active: filters.active,
      }
      params.value = Object.assign({}, _params)

      apiClient
        .get('/api/warehouses', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
          isFetching.value = false
        })
        .catch(error => {
          isFetching.value = false
        })
    }

    const startRow = ref(1)
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    // TODO: Lihat model
    const viewDetail = record => {
      modal2Visible.value = true
      formState.value.title_state = 'Lihat Gudang'
      formState.value.readOnly = true
      formState.value.isView = true
      formState.value.isEdit = false
      formState.value.isNew = false
      formState.value.id = record.id
      formState.value.record = record
    }

    //  const setActive = id => {
    //   apiClient
    //     .get(`/api/warehouses/${id}`)
    //     .then(response => {
    //       const data = response.data
    //       formState.value = data
    //       modal3Visible.value = true
    //       formState.value.title_state = 'Edit Status'
    //       formState.value.state = 'status'
    //       formState.value.readOnly = false
    //     })
    //     .catch(error => console.error(error))
    // }

    provide('formState', formState)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isDownloading: false,
    })

    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    function onFinish() {
      modal2Visible.value = false
      fetchData()
    }
    // filters
    // button search
    const search = () => {
      fetchData()

      if (filters.status == 0) {
        showNonaktifBtn.value = false
      } else {
        showNonaktifBtn.value = true
      }
    }
    // todo: test
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/warehouses', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `master-gudang_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    // todo: check
    const nonaktifkan = () => {
      // modal1Visible.value = false
      if (filters.reason === '') {
        errorMessage.value = 'Alasan tidak boleh kosong'
      } else {
        const promises = []
        state.loading = true

        let _params = {
          reason: filters.reason,
        }

        state.selectedRowKeys.forEach(k => {
          promises.push(apiClient.post('/api/warehouses-active/' + k + '/no', _params))
        })

        Promise.all(promises)
          .then(() => {
            console.log('completed')
            message.success('Gudang Berhasil dinonaktifkan')
            filters.reason = ''
          })
          .finally(err => {
            state.selectedRowKeys = []
            modal1Visible.value = false
            fetchData()
            state.loading = false
            filters.reason = ''
          })
      }
    }

    const showConfirm = visible => {
      if (visible) {
        if (state.selectedRowKeys.length === 0) {
          Modal.warning({
            content: 'Checklist gudang yang akan di aktifkan!',
          })
          return
        }
      }
      modal3Visible.value = visible
    }

    const setActive = () => {
      // modal1Visible.value = false

      const promises = []
      state.loading = true
      state.selectedRowKeys.forEach(k => {
        promises.push(apiClient.post('/api/warehouses-active/' + k + '/yes'))
      })

      Promise.all(promises)
        .then(response => {
          response.forEach(dataResponse => {
            dataResponse.data.status === 'error'
              ? message.error('Gudang tidak dapat di aktifkan kembali')
              : message.success('Gudang Berhasil diaktifkan')
          })
          console.log('completed')
        })
        .finally(err => {
          state.selectedRowKeys = []
          modal3Visible.value = false
          fetchData()
          state.loading = false
        })
    }

    // const simpan = () => {
    //   if (formState.value.state === 'status') {
    //     Modal.confirm({
    //       title: 'Konfirmasi Edit Status',
    //       content: 'Apakah anda ingin melanjutkan merubah status ?',

    //       onOk() {
    //         apiClient
    //           .post('/api/warehouses/' + formState.value.id + '?_method=PUT', formState.value)
    //           .then(({ data }) => {
    //             modal3Visible.value = false
    //             state.loading = false
    //             fetchData()
    //             message.success('Berhasil disimpan')
    //           })
    //           .catch(e => message.error('Gagal menyimpan, silahkan pilih status'))
    //           .finally(e => {
    //             state.loading = false
    //           })
    //       },
    //       onCancel() {},
    //     })
    //   }
    // }
    //

    // handle upload
    const visibleUploadModal = ref(false);

    const btnUpload = () => {
        visibleUploadModal.value = true
    }
    
    return {
      modal1Visible,
      modal2Visible,
      modal3Visible,
      setModal1Visible,
      q,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      startRow,
      handleTableChange,
      meta,
      formState,
      viewDetail,
      ...toRefs(state),
      ...toRefs(filters),
      search,
      fetchXlsx,
      nonaktifkan,
      // temp
      filters,
      onSelectChange,
      onFinish,
      isFetching,
      errorMessage,
      status,
      type,
      active,
      showConfirm,
      // simpan,
      setActive,
      showNonaktifBtn,
      // upload
      visibleUploadModal,
      btnUpload,
      // only roles
      ROLE_ADMIN_BK,
      ROLE_ADMIN_IO,
      hasRoles,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
