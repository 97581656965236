<template>
    <AModal
        :visible="visible"
        title="List Toko"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">

        <template v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])">
            <AForm
                v-if="state.isForm"
                ref="formRef"
                class="myform"
                :model="form"
                :rules="state.rules"
                @finish="handleOk"
                :wrapper-col="{ span: 14 }"
                :scroll-to-first-error="true">

                <!-- distributor -->
                <a-row
                    v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                    class="form-row">
                    <a-col :sm="24">
                        <a-form-item
                            label="Pilih Distributor"
                            label-align="left"
                            :label-col="{ sm: { span: 4 } }"
                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                            required
                            name="distributor_id"
                            has-feedback>
                            <FilterATSalesmanDistributorSalesman
                                v-model:salesman_id="form.salesman_id"
                                v-model:value="form.distributor_id"
                                style="width:100%;"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <!-- TSO -->
                <a-row
                    v-if="hasRoles([ROLE_DISTRIBUTOR])"
                    class="form-row">
                    <a-col :sm="24">
                        <a-form-item
                            label="Pilih TSO"
                            label-align="left"
                            :label-col="{ sm: { span: 4 } }"
                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                            required
                            name="tso_id"
                            has-feedback>
                                <FilterUserByVendorMapping
                                    style="width:100%"
                                    placeholder="Pilih TSO"
                                    :role_id="55414"
                                    :mode="null"
                                    :disabled="item.readOnly"
                                    v-model:value="form.tso_id"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <!-- toko -->
                <a-row class="form-row">
                    <a-col :sm="24">
                        <a-form-item
                            label="Pilih Toko"
                            label-align="left"
                            :label-col="{ sm: { span: 4 } }"
                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                            required
                            name="customer_id"
                            has-feedback>
                            <FilterATSalesmanCustomer
                                v-model:value="form.customer_id"
                                v-model:tso="form.tso_id"
                                v-model:distributor="form.distributor_id"
                                style="width:100%;"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <!-- submit -->
                <a-row class="form-row mt-2">
                    <ACol :sm="4"/>
                    <a-col :sm="20">
                        <ASpace>
                            <AButton
                                type="primary"
                                html-type="submit"
                                :loading="form.busy"
                                :disabled="form.busy">Simpan</AButton>
                            <AButton
                                title="Batal"
                                @click="state.isForm = !state.isForm; form.reset()">Batal</AButton>
                        </ASpace>
                    </a-col>
                </a-row>
            </AForm>
            <div
                v-else
                class="row justify-content-end">
                <div class="col-auto">
                    <ASpace>
                        <ATooltip
                            v-if="hasRoles([ROLE_TSO])"
                            title="Approve">
                            <AButton
                                :loading="state.isActionApprove"
                                @click="btnActionApprove"
                                type="primary">
                                <span class="fa fa-check mr-2" aria-hidden="true"/> Approve
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_TSO])"
                            title="Reject">
                            <AButton
                                :loading="state.isActionReject"
                                @click="btnActionReject"
                                type="danger">
                                <span class="fa fa-ban mr-2" aria-hidden="true"/> Reject
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                            title="Upload Dokumen">
                            <AButton
                                type="primary"
                                @click="btnUpload">
                                <i class="fa fa-upload" aria-hidden="true"></i>
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                            title="Tambahkan">
                            <AButton
                                @click="state.isForm = !state.isForm"
                                type="primary">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Download excel">
                            <DownloadExcel
                                :url="state.endpoint.current"
                                :params="state.params"
                                namefile="List-Toko-Mapping-Salesman"
                                @errors="errorMessage"/>
                        </ATooltip>
                    </ASpace>
                </div>
            </div>
        </template>
        
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <div v-if="state.errors.length" class="mt-2">
            <AAlert
                v-for="(item, index) in state.errors"
                class="mb-2"
                :type="item.status ? 'success' : 'error'"
                :key="index"
                :message="item.message"
                banner
                closable
                @close="state.errors = []" />
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                row-key="approval_id"
                :row-selection="hasRoles([ROLE_TSO]) ? rowSelection : null"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                            title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- upload -->
        <UploadTokoList
            v-if="visibleUploadModal"
            v-model:visible="visibleUploadModal"
            v-model:item="visibleUploadItemModal"
            @success="fetchDataList"/>
    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterATSalesmanCustomer from '@/components/filter/FilterATSalesmanCustomer'
import FilterATSalesmanDistributorSalesman from '@/components/filter/FilterATSalesmanDistributorSalesman'
import FilterUserByVendorMapping from '@/components/filter/FilterUserByVendorMapping'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import UploadTokoList from './UploadTokoList'
import Form from 'vform'
import { pickBy, includes } from 'lodash'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_TSO,
    ROLE_TIM_CRM,
    ROLE_DISTRIBUTOR,
    ROLE_HELPDESK,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterATSalesmanCustomer,
        FilterATSalesmanDistributorSalesman,
        FilterUserByVendorMapping,
        DownloadExcel,
        UploadTokoList,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()
        const formRef = ref();

        const form = reactive(new Form({
            salesman_id: props.item.id,
            distributor_id: null,
            tso_id: null,
            customer_id: [],
        }))

        const state = reactive({
            columns: [
                {
                    title: 'Kode Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Kode Dist',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor_name',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: `/api/users-salesman/customers/${props.item.id}`,
                current_distributor: `/api/users-salesman/by-distributor/customers`,
                approve: '/api/users-salesman/customer/request-approve',
                reject: '/api/users-salesman/customer/request-reject',
            },
            errors: [],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                page: 1,
                "per-page": 10,
            },
            isForm: false,
            isActionApprove: false,
            isActionReject: false,
            rules: {
                distributor_id: [
                    {
                        required: true,
                        message: "Distributor tidak boleh kosong!",
                    },
                ],
                customer_id: [
                    {
                        required: true,
                        message: "Toko tidak boleh kosong!",
                    },
                ],
                tso_id: [
                    {
                        required: true,
                        message: "TSO tidak boleh kosong!",
                    },
                ],
            },
            selectedRows: [],
            selectedRowKeys: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const rowSelection = computed(() => {
            return {
                selectedRowKeys: state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    state.selectedRowKeys = selectedRowKeys
                    state.selectedRows = selectedRows
                },
                getCheckboxProps: record => ({
                    disabled: !record.approval_action, // Column configuration not to be checked
                }),
            }
        })

        const handleOk = async () => {
            state.errors = []
            
            // validation form
            await formRef.value.validate()
                .catch(() => { });

            let endpoint = hasRoles([ROLE_DISTRIBUTOR])
                ? state.endpoint.current_distributor
                : state.endpoint.current

            // exceprt role distributor
            if (!hasRoles([ROLE_DISTRIBUTOR])) {
                let customers = []
                form.customer_id.forEach(item => {
                    customers.push({
                        vendor_id: form.distributor_id,
                        customer_id: item,
                    })
                })
                form.customers = customers
            }

            form.post(endpoint)
                .then(({ data }) => {
                    if (data === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }

                    if (data.success && state.isForm) {
                        form.reset()
                        formRef.value.resetFields()
                        state.isForm = false
                        fetchDataList()
                        message.success(data.message)
                    }
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText, data } = error.response

                        if (status === 400) {
                            data.message.forEach(item => {
                                state.errors.push({
                                    message: item,
                                    status: data.status,
                                })
                            })
                        }

                        if (status === 500) {
                            const message = data.message
                            if (Array.isArray(message)) {
                                state.errors = message
                            } else {
                                errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                            }
                        }
                    }
                })

        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint.current, {
                    params: pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isDelete = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // btn delete
        const btnDelete = (record) => {
            Modal.confirm({
                title: 'Konfirmasi Hapus',
                content: 'Apakah anda ingin hapus item tersebut?',
                onOk() {
                    record.isDelete = true

                    apiClient
                        .post(`/api/users-salesman/customers/${props.item.id}/delete`, {
                            id: record.id,
                        })
                        .then(({ data }) => {
                            fetchDataList()
                            message.success(data.message)
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                        .finally(() => {
                            record.isDelete = false
                        })
                },
                onCancel() { },
            })
        }

        const btnActionApprove = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Approve',
                content: 'Apakah Anda ingin approve?',
                onOk() {
                    state.isActionApprove = true
                    
                    apiClient.post(state.endpoint.approve, {
                            approval_id: state.selectedRows.map(item => item.approval_id),
                        })
                        .then(({ data }) => {
                            if (data.status == 'success') {
                                message.success(data.message)
                            }

                            state.selectedRowKeys = []
                            fetchDataList()
                        })
                        .finally(() => {
                            state.isActionApprove = false
                        })
                },
                onCancel() { },
            })
        }

        const btnActionReject = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Reject',
                content: 'Apakah Anda ingin reject?',
                onOk() {
                    state.isActionReject = true
                    
                    apiClient.post(state.endpoint.reject, {
                            approval_id: state.selectedRows.map(item => item.approval_id),
                        })
                        .then(({ data }) => {
                            if (data.status == 'success') {
                                message.success(data.message)
                            }

                            state.selectedRowKeys = []
                            fetchDataList()
                        })
                        .finally(() => {
                            state.isActionReject = false
                        })
                },
                onCancel() { },
            })
        }

        // handle upload
        const visibleUploadModal = ref(false);
        const visibleUploadItemModal = ref(null);

        const btnUpload = () => {
            visibleUploadModal.value = true
            visibleUploadItemModal.value = props.item
        }

        onMounted(() => {
            fetchDataList()
            
            // only action column
            if (!hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['action'], item.dataIndex)) {
                        return item
                    }
                })
            }

            // only status column
            if (hasRoles([ROLE_DISTRIBUTOR, ROLE_TSO])) {
                state.columns.splice(5, 0, {
                    title: 'Status',
                    dataIndex: 'status_approval',
                })
            }
        })

        return {
            fetchDataList,
            state,
            formRef,
            form,
            handleModalCancel,
            handleTableChange,
            handleOk,
            btnDelete,
            errorMessage,
            visibleUploadModal,
            visibleUploadItemModal,
            btnUpload,
            btnActionApprove,
            btnActionReject,
            rowSelection,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_TSO,
            ROLE_TIM_CRM,
            ROLE_DISTRIBUTOR,
            ROLE_HELPDESK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
