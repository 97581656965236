// Helper untuk Query Builder PostgreSQL
const QueryBuilder = {
    context: null,

    // Inisialisasi konteks
    initialize() {
        this.context = {
            query: '',
            type: '',
            table: '',
            columns: ['*'],
            values: [],
            conditions: [],
            joins: [],
            orderBy: '',
            limit: null,
            offset: null,
        }
        return this
    },

    select(columns = ['*']) {
        this.context.type = 'SELECT'
        this.context.columns = columns
        return this
    },

    insert(table, data) {
        this.context.type = 'INSERT'
        this.context.table = table
        this.context.columns = Object.keys(data)
        this.context.values = Object.values(data)
        return this
    },

    update(table, data) {
        this.context.type = 'UPDATE'
        this.context.table = table
        this.context.values = data
        return this
    },

    delete(table) {
        this.context.type = 'DELETE'
        this.context.table = table
        return this
    },

    from(table) {
        this.context.table = table
        return this
    },

    where(column, operator, value = null) {
        const validOperators = [
            '=', '<', '>', '<=', '>=', '!=', '~', '!~', 
            'LIKE', 'LIKE %%', 'ILIKE', 'ILIKE %%', 
            'IN', 'NOT IN', 'IS NULL', 'IS NOT NULL', 
            'NOT LIKE',
        ]

        if (!validOperators.includes(operator)) {
            throw new Error(`Invalid operator: ${operator}`)
        }

        let condition

        // Handle operator IS NULL dan IS NOT NULL
        if (['IS NULL', 'IS NOT NULL'].includes(operator)) {
            condition = `${column} ${operator}`
        }
        // Ensure value is an array for IN and NOT IN
        else if (['IN', 'NOT IN'].includes(operator)) {
            if (!Array.isArray(value)) {
                throw new Error(`Operator ${operator} requires an array as value.`)
            }
            const placeholders = value.map(() => `$${this.context.values.length + 1}`).join(', ')
            condition = `${column} ${operator} (${placeholders})`
            this.context.values.push(...value)
        }
        // Handle LIKE, ILIKE, and NOT LIKE with casting
        else if (['LIKE', 'LIKE %%', 'ILIKE', 'ILIKE %%', 'NOT LIKE'].includes(operator)) {
            if (Array.isArray(value)) {
                throw new Error(`Operator ${operator} does not support array as value.`)
            }
            const formattedValue = operator.includes('%%') ? `%${value}%` : value

            // Handle NOT LIKE explicitly
            const sqlOperator = operator === 'NOT LIKE' ? 'NOT LIKE' : operator.split(' ')[0]

            condition = `CAST(${column} AS text) ${sqlOperator} $${this.context.values.length + 1}`
            this.context.values.push(formattedValue)
        }
        // Handle other operator (single value)
        else {
            if (Array.isArray(value)) {
                throw new Error(`Operator ${operator} does not support array as value.`)
            }
            condition = `${column} ${operator} $${this.context.values.length + 1}`
            this.context.values.push(value)
        }

        this.context.conditions.push(condition)

        return this
    },

    orderBy(column, direction = 'ASC') {
        this.context.orderBy = `ORDER BY ${column} ${direction}`
        return this
    },

    limit(count) {
        this.context.limit = count
        return this
    },

    offset(count) {
        this.context.offset = count
        return this
    },

    build() {
        const { type, table, columns, values, conditions, joins, orderBy, limit, offset } = this.context

        if (type === 'SELECT') {
            this.context.query = `SELECT ${columns.join(', ')} FROM ${table}`
        } else if (type === 'INSERT') {
            const placeholders = values.map((_, i) => `$${i + 1}`).join(', ')
            this.context.query = `INSERT INTO ${table} (${columns.join(', ')}) VALUES (${placeholders})`
        } else if (type === 'UPDATE') {
            const setClause = Object.keys(values)
                .map((key, i) => `${key} = $${i + 1}`)
                .join(', ')
            this.context.query = `UPDATE ${table} SET ${setClause}`
        } else if (type === 'DELETE') {
            this.context.query = `DELETE FROM ${table}`
        }

        if (joins.length) {
            this.context.query += ` ${joins.join(' ')}`
        }

        if (conditions.length) {
            this.context.query += ` WHERE ${conditions.join(' AND ')}`
        }

        if (orderBy) {
            this.context.query += ` ${orderBy}`
        }

        if (limit !== null) {
            this.context.query += ` LIMIT ${limit}`
        }

        if (offset !== null) {
            this.context.query += ` OFFSET ${offset}`
        }

        return this.context.query
    },

    getValues() {
        return this.context.values
    },

    debugQuery() {
        let debugQuery = this.context.query
        this.context.values.forEach((value, index) => {
            const placeholder = `$${index + 1}`
            const formattedValue = typeof value === 'string' ? `'${value}'` : value
            debugQuery = debugQuery.replace(placeholder, formattedValue)
        })
        return debugQuery
    },
}

export default QueryBuilder