<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <ATextarea
            placeholder="command console" 
            rows="4"
            v-model:value="state.params.command"/>

        <ASpace>
            <AButton
                type="primary"
                title="cari"
                class="mt-2"
                @click="fetchDataList"
                :loading="state.isFetching">
                <span
                    v-if="!state.isFetching"
                    class="fa fa-search"
                    aria-hidden="true"/>
                <span v-else>
                    Memuat Data ...
                </span>
            </AButton>
        </ASpace>

        <!-- result console -->
        <div class="card mt-4">
            <div class="card-body" style="background: #e3e3e3;">
                <pre id="resultApi" style="user-select:none;"></pre>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [],
            endpoint: '/api/run-command',
            data: [],
            meta: {
                per_page: 30,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                command: '',
            }),
            fields: [],
            field: {
                field: null,
                operator: null,
                value: null,
                valuein: [],
            },
            active: '1',
        })

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .post(state.endpoint, queryParams())
                .then(({ data }) => {
                    var resultApi = document.getElementById('resultApi')

                    // Proses teks output dari API
                    var lines = data.output.split('\n') // Pisahkan berdasarkan '\n'

                    // Render setiap baris menjadi elemen div
                    resultApi.innerHTML = '' // Kosongkan isi sebelumnya
                    lines.forEach(line => {
                        var div = document.createElement('div')
                        div.textContent = line // Set teks untuk setiap baris
                        resultApi.appendChild(div) // Tambahkan ke dalam resultApi
                    })
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        return {
            state,
            fetchDataList,
            errorMessage,
            queryParams,
        }
    },
})
</script>
