<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Large Toko"
    allow-clear
    show-search
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="select"
    @dropdownVisibleChange="dropdownVisibleChange"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, merge, keyBy, values } from 'lodash'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      type: String,
      default: 'multiple',
    },
    region: {
      default: () => [],
      type: Array,
    },
    area: {
      default: () => [],
      type: Array,
    },
    kabupaten: {
      default: () => [],
      type: Array,
    },
    propinsi: {
      default: () => [],
      type: Array,
    },
    distributor: {
      default: () => [],
      type: Array,
    },
    joinOptions: {
      default: () => [],
      type: Array,
    },
    forInput: {
      default: false,
      type: Boolean,
    },
  },
  emits: ['update:value', 'select'],
  setup(props, { emit }) {
    const data = ref([])
    const findText = ref(null)
    const { region, area, kabupaten, propinsi, distributor, value } = toRefs(props)
    const loading = ref(false)
    const joinOptions = toRefs(props).joinOptions

    const fetchDataRegional = debounce(
      (region, area, kabupaten, propinsi, distributor, q = null) => {
        if (region.length <= 0) region = null
        if (area.length <= 0) area = null
        if (kabupaten.length <= 0) kabupaten = null
        if (propinsi.length <= 0) propinsi = null
        if (distributor.length <= 0) distributor = null
        loading.value = true
        const url = props.forInput ? '/api/filter/lt' : '/api/filter/lt-by-role'
        apiClient
          .get(url, {
            params: {
              region: region,
              area: area,
              kabupaten: kabupaten,
              provinsi: propinsi,
              distributor: distributor,
              q,
            },
          })
          .then(response => {
            let sorted = response.data
            if (joinOptions.value.length > 0) {
              const merged = merge(keyBy(joinOptions.value, 'id'), keyBy(sorted, 'id'))
              sorted = values(merged)
            }
            data.value = sorted.sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
            )
          })
          .catch(err => console.log(err))
          .finally(() => {
            loading.value = false
          })
      },
      500,
    )

    onMounted(() => {
      fetchDataRegional(
        region.value,
        area.value,
        kabupaten.value,
        propinsi.value,
        distributor.value,
      )
    })

    watch(region, (after, before) => {
      emit('update:value', [])
      fetchDataRegional(after, area.value, kabupaten.value, propinsi.value, distributor.value)
    })

    watch(area, (after, before) => {
      emit('update:value', [])
      fetchDataRegional(region.value, after, kabupaten.value, propinsi.value, distributor.value)
    })

    watch(kabupaten, (after, before) => {
      emit('update:value', [])
      fetchDataRegional(region.value, area.value, after, propinsi.value, distributor.value)
    })

    watch(propinsi, (after, before) => {
      emit('update:value', [])
      fetchDataRegional(region.value, area.value, kabupaten.value, after, distributor.value)
    })

    watch(distributor, (after, before) => {
      emit('update:value', [])
      fetchDataRegional(region.value, area.value, kabupaten.value, propinsi.value, after)
    })

    watch(value, (after, before) => {
      if (after === null || after === undefined || after === [])
        fetchDataRegional(
          region.value,
          area.value,
          kabupaten.value,
          propinsi.value,
          distributor.value,
        )
    })

    const onSearch = debounce(value => {
      loading.value = true
      findText.value = value
      fetchDataRegional(
        region.value,
        area.value,
        kabupaten.value,
        propinsi.value,
        distributor.value,
        value,
      )
    }, 300)

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const select = value => {
      if (findText.value !== null) {
        fetchDataRegional(
          region.value,
          area.value,
          kabupaten.value,
          propinsi.value,
          distributor.value,
        )
        findText.value = null
      }

      emit('select', value)
    }

    const dropdownVisibleChange = value => {
      if (findText.value !== null) {
        fetchDataRegional(
          region.value,
          area.value,
          kabupaten.value,
          propinsi.value,
          distributor.value,
        )
        findText.value = null
      }
    }

    return {
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>
