<template>
  <a-form
    ref="formRef"
    class="myform"
    :model="formState"
    :rules="rules"
    :wrapper-col="{ span: 14 }"
    :scroll-to-first-error="true"
  >
    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Title"
          label-align="left"
          :label-col="{ sm: { span: 5 } }"
          :wrapper-col="{ sm: { span: 24 - 5 } }"
          required
          has-feedback
          name="title"
        >
          <a-input
            placeholder="Title Manual Book"
            v-model:value="formState.title"
            :disabled="formState.readOnly"
            required
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Slug"
          :label-col="{ sm: { span: 5 } }"
          :wrapper-col="{ sm: { span: 24 - 5 } }"
          label-align="left"
        >
          <a-input
            placeholder="Slug ..."
            v-model:value="formState.slug"
            :disabled="formState.readOnly"
            :auto-size="{ minRows: 5 }"
          />
        </a-form-item
      ></a-col>
    </a-row>

    <template v-if="formState.readOnly">
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Tipe"
            :label-col="{ sm: { span: 5 } }"
            :wrapper-col="{ sm: { span: 24 - 5 } }"
            label-align="left"
          >
            <a-input
              v-model:value="formState.type"
              :disabled="formState.readOnly"
              :auto-size="{ minRows: 5 }"
            />
          </a-form-item
        ></a-col>
      </a-row>
    </template>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Dokumen Manual Book"
          label-align="left"
          :label-col="{ sm: { span: 5 } }"
          :wrapper-col="{ sm: { span: 24 - 5 } }"
          required
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!document || document === 'null'"
              type="file"
              accept="application/pdf"
              @change="e => onFileChange(e, 'document')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  document = null
                }
              "
              >Hapus Dokumen</a-button
            ><br />
          </template>
          <a
            v-if="
              document !== null &&
                document !== undefined &&
                typeof document === 'string' &&
                document !== 'null'
            "
            :href="!document ? 'javascript.void(0)' : document"
            target="_blank"
            >Lihat Dokumen</a
          >
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import { ref, reactive, inject, computed, nextTick, toRef, onBeforeMount, toRefs } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import rules from './rules'

export default {
  components: {},
  setup(props, { emit }) {
    const segment = ref(1)
    const defWrapCol = reactive({ md: { span: 16, offset: 4 }, sm: { span: 24 } })
    const formState = inject('formState')
    const document = toRef(formState.value, 'file')
    const readOnly = false
    const visible = ref(false)

    const formRef = ref('')

    onBeforeMount(() => {})

    const isNew = computed(() => formState.value.state === 'new')

    const urlUpload = apiClient.defaults.baseURL + '/api/upload'
    // filelist upload
    const ifDokumen = ref(true)

    const action = file => {
      var FormData = require('form-data')
      var data = new FormData()
      return new Promise((resolve, reject) => {
        return resolve({
          data: { satu: 'https:\/\/storage.aksestoko.id\/f\/20f2a783-8d2f-4896-988f-86cfb0d8c93a.png' },
        })
      })
    }

    const onFileChange = async (e, type) => {
      var files = e.target.files || e.dataTransfer.files
      console.log(files.length, type)
      if (!files.length) return
      var filesize = (files[0].size / 1024 / 1024).toFixed(4) // MB
      if (filesize > 8.0) {
        message.warning('Ukuran harus di bawah 8MB!', 5.0)
        if ('document' === type) {
          ifDokumen.value = false
        }
        await nextTick()
        if ('document' === type) {
          ifDokumen.value = true
        }
        return
      }

      if ('document' === type) {
        document.value = files[0]
        // action(files[0]).then(({data}) => {document.value = data.satu});
        // imageToBase64(files[0], document)
      }
    }

    const imageToBase64 = (file, to) => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        to.value = reader.result
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    }

    return {
      segment,
      defWrapCol,
      formState,
      isNew,
      visible,
      urlUpload,
      action,
      onFileChange,
      ifDokumen,
      document,
      imageToBase64,
      formRef,
      rules,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
