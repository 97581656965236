<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Mulai Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Akhir Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterToko
                    class="mr-2"
                    v-model:value="state.params.toko"/>
                <FilterProgram
                    class="mr-2"
                    v-model:value="state.params.program"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12 mt-auto">
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Laporan-Registrasi-Program-Apresiasi-Toko-2022"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import FilterProgram from '@/components/filter/FilterProgram'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FilterProgram,
        FilterRegional,
        FilterArea,
        FilterToko,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Nama Program',
                    dataIndex: 'field',
                },
                {
                    title: 'Regional Program',
                    dataIndex: 'field',
                },
                {
                    title: 'Tanggal Transaksi',
                    dataIndex: 'field',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'field',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'field',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'field',
                },
                {
                    title: 'Regional Toko',
                    dataIndex: 'field',
                },
                {
                    title: 'Paket Program',
                    dataIndex: 'field',
                },
                {
                    title: 'Tipe',
                    dataIndex: 'field',
                },
                {
                    title: 'Nama Pemilik',
                    dataIndex: 'field',
                },
                {
                    title: 'Nama Sales',
                    dataIndex: 'field',
                },
                {
                    title: 'No Telepon',
                    dataIndex: 'field',
                },
                {
                    title: 'Email',
                    dataIndex: 'field',
                },
                {
                    title: 'Lampiran',
                    dataIndex: 'field',
                },
                {
                    title: '1st Main Brand',
                    dataIndex: 'field',
                },
                {
                    title: '2nd Main Brand',
                    dataIndex: 'field',
                },
                {
                    title: 'Eco Brand',
                    dataIndex: 'field',
                },
            ],
            endpoint: '',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            loadingGraph: false,
            loadingArea: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                region: [],
                program: [],
                area: [],
                kabupaten: [],
                toko: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).subtract(0, 'days'),
                page: 1,
                "per-page": 10,
            }),
            selectedRows: [],
            selectedRowKeys: [],
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            btnFetchData()
        }

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnFetchData = () => {
            fetchDataList()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            // rowSelection,
            handleTableChange,
            onSelectChange,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            queryParams,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>