<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Mulai Tanggal"
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Akhir Tanggal"
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
                <ASelect
                    class="mr-2"
                    v-model:value="state.params.status"
                    allow-clear
                    :options="state.statusLists"
                    placeholder="Pilih Status"
                    style="width:300px;"/>
                <!-- <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:300px"/> -->
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12 mt-auto">
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="state.params"
                    namefile="Laporan-Registrasi-Aksestoko"
                    @errors="errorMessage" />
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch, onBeforeMount } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FilterRegional,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Tanggal Aktivasi',
                    dataIndex: 'activatedAt',
                    width: 100,
                },
                {
                    title: 'Status Toko',
                    dataIndex: 'status',
                    width: 100,
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'name',
                    width: 150,
                },
                {
                    title: 'Nama Pemilik Toko',
                    dataIndex: 'name_owner',
                    width: 150,
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                },
                {
                    title: 'No HP',
                    dataIndex: 'phone',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                    width: 250,
                },
                {
                    title: 'Provinsi Toko',
                    dataIndex: 'province',
                    width: 100,
                },
                {
                    title: 'Region Toko',
                    dataIndex: 'region',
                    width: 100,
                },
                {
                    title: 'Distributor',
                    dataIndex: 'distributors',
                    width: 100,
                },
                {
                    title: 'Registered By',
                    dataIndex: 'registered_by',
                    width: 100,
                },
                {
                    title: 'Kode Salesperson',
                    dataIndex: 'salesman_code',
                    width: 100,
                },
                {
                    title: 'Nama Salesperson',
                    dataIndex: 'salesman_name',
                    width: 100,
                },
            ],
            endpoint: '/api/report/laporan-registrasi-akses-toko',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                status: null,
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).subtract(0, 'days'),
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                {
                    label: 'Aktif',
                    value: 1,
                },
                {
                    label: 'Tidak Aktif',
                    value: 0,
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            btnFetchData()
        }

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { customerRegistrations, meta } = data

                    state.data = []
                    
                    customerRegistrations.forEach(item => {
                        state.data.push({
                            activatedAt: item.user.activatedAt,
                            code: item.customer.code,
                            name: item.customer.name,
                            name_owner: item.customer.ownerName,
                            area: item.customer.area,
                            phone: item.customer.phone,
                            email: item.customer.email,
                            address: item.customer.address,
                            province: item.customer.province,
                            region: item.customer.region,
                            status: item.customer.status,
                            registered_by: `${item.user.firstName} ${item.user.lastName}`,
                            distributors: item.distributors.map((item) => item.name).toString(),
                            salesman_code: item.salesman.code,
                            salesman_name: item.salesman.name,
                        })
                    })

                    state.meta.page = meta.currentPage
                    state.meta.per_page = meta.perPage
                    state.meta.total = meta.total
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnFetchData = () => {
            fetchDataList()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            handleTableChange,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>