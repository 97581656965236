<template>
    <span>
        {{ label }}
        <ATooltip v-if="tooltip" :title="tooltip">
            <InfoCircleOutlined style="color: rgba(0, 0, 0, 0.45)" />
        </ATooltip>
    </span>
</template>

<script>
import { InfoCircleOutlined } from '@ant-design/icons-vue'
export default {
    name: "LabelWithTooltip",
    components: {
        InfoCircleOutlined,
    },
    props: {
        label: {
            type: [String],
            required: true,
        },
        tooltip: {
            type: [String],
            default: null,
        },
    },
}
</script>
