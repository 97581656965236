<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Monitoring-Harga"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.endpoint"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick } from 'vue'
import {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterDistributor,
} from '@/components/filter'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FilterRegional,
        FilterProvince,
        FilterArea,
        FilterDistributor,
        DownloadExcel,
        TableStandard,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Regional',
                    dataIndex: 'region_toko',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'provinsi_toko',
                },
                {
                    title: 'Area',
                    dataIndex: 'area_toko',
                },
                {
                    title: 'Distrik',
                    dataIndex: 'kabupaten_toko',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'nama_produk',
                },
                {
                    title: 'Principal',
                    dataIndex: 'brand',
                },
                {
                    title: 'Date',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'Type',
                    dataIndex: 'type',
                },
                {
                    title: 'Kemasan',
                    dataIndex: 'kemasan',
                },
                {
                    title: 'Ukuran Kemasan',
                    dataIndex: 'ukuran_kemasan',
                },
                {
                    title: 'Period',
                    dataIndex: 'period',
                },
                {
                    title: 'Year',
                    dataIndex: 'year',
                },
                {
                    title: 'Month',
                    dataIndex: 'month',
                },
                {
                    title: 'Week',
                    dataIndex: 'week',
                },
                {
                    title: 'RBP Gross',
                    dataIndex: 'rbp_gross',
                },
                {
                    title: 'Promo Untuk Toko',
                    dataIndex: 'promo',
                },
                {
                    title: 'RBP Nett',
                    dataIndex: 'rbp_nett',
                },
                {
                    title: 'RSP',
                    dataIndex: 'rsp',
                },
                {
                    title: 'Promo Untuk Customer',
                    dataIndex: 'nama_promo',
                },
                {
                    title: 'Nama Promo Untuk Toko',
                    dataIndex: 'promo_untuk_toko',
                },
                {
                    title: 'Nama Promo Untuk Customer',
                    dataIndex: 'promo_untuk_customer',
                },
                {
                    title: 'Margin Toko',
                    dataIndex: 'margin_toko',
                },
            ],
            endpoint:'/api/report-ats/weekly-price-monitoring',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                area: [],
                distributor: [],
                page: 1,
                "per-page": 10,
            }),
        })

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
        }
    },
})
</script>
