<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterGudang
                    style="width:100%;"
                    v-model:distributor="state.params.distributor"
                    v-model:value="state.params.gudang"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProduct
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.product"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        title="Approve">
                        <AButton
                            :loading="state.isActionApprove"
                            @click="btnActionRequest(true)"
                            type="primary">
                            <span class="fa fa-check mr-2" aria-hidden="true"/> Approve
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Reject">
                        <AButton
                            :loading="state.isActionReject"
                            @click="btnActionRequest"
                            type="danger">
                            <span class="fa fa-ban mr-2" aria-hidden="true"/> Reject
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                row-key="id"
                :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            title="Approve">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isActionApprove"
                                @click="btnRequestSingle(record, true)">
                                <span class="fa fa-check" aria-hidden="true" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            title="Reject">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isActionReject"
                                @click="btnRequestSingle(record)">
                                <span class="fa fa-ban" aria-hidden="true" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>

                <template #expandedRowRender="{ record }">
                    <ATable
                        :columns="state.columnsDetails"
                        :data-source="record.details"
                        :pagination="false"/>
                </template>
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, h } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterGudang from '@/components/filter/FilterGudang'
import FilterProduct from '@/components/filter/FilterProduct'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        DownloadExcel,
        FilterDistributor,
        FilterGudang,
        FilterProduct,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Adjustment',
                    dataIndex: 'adjustment_code',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'warehouse_code',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'warehouse',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            columnsDetails: [
                {
                    title: 'Nama Produk',
                    dataIndex: 'product',
                },
                {
                    title: 'Tipe Adjustment',
                    dataIndex: 'adjusment_type',
                },
                {
                    title: 'QTY',
                    dataIndex: 'quantity',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Catatan',
                    dataIndex: 'notes',
                },
            ],
            endpoint: {
                current: '/api/report/adjustment-stock-approvals',
                request: '/api/report/adjustment-stock-approvals', // /api/report/adjustment-stock-approvals/{id}
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isActionApprove: false,
            isActionReject: false,
            params: extractQueryParams({
                q: '',
                distributor: [],
                gudang: [],
                product: [],
                page: 1,
                "per-page": 10,
            }),
            selectedRows: [],
            selectedRowKeys: [],
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnActionRequest = (isApprove = false) => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: `Konfirmasi ${isApprove ? 'Approve' : 'Reject'}`,
                content: `Apakah Anda ingin ${isApprove ? 'approve' : 'reject'}?`,
                onOk() {
                    if (isApprove) {
                        state.isActionApprove = true
                    } else {
                        state.isActionReject = true
                    }

                    // Kirim semua request dalam bentuk array promise
                    const promises = state.selectedRows.map((record) => {
                        return apiClient
                            .post(`${state.endpoint.request}/${record.id}`, {
                                status: isApprove ? 'approve' : 'reject',
                            })
                    })

                    // Tunggu semua request selesai menggunakan Promise.all
                    Promise.all(promises)
                        .then((results) => {

                            let messages = []
                            results.forEach((item) => {
                                if (!item.data.success) {
                                    messages.push(h('p', `- ${item.data.message}`))
                                }
                            })

                            if (messages.length) {
                                if (state.selectedRowKeys.length != messages.length) {
                                    message.success(`Berhasil di ${isApprove ? 'approve' : 'reject'}, beberapa ada yang gagal.`)
                                }

                                Modal.info({
                                    title: `Terdapat ${isApprove ? 'approve' : 'reject'} yang gagal di perbarui!`,
                                    content: h('div', {}, messages),
                                    width: 700,
                                    onOk() {
                                    },
                                })
                            } else {
                                message.success(`Berhasil mengubah status ${isApprove ? 'approve' : 'reject'}.`)
                            }

                            state.selectedRowKeys = []
                            fetchDataList()
                        })
                        .finally(() => {
                            if (isApprove) {
                                state.isActionApprove = false
                            } else {
                                state.isActionReject = false
                            }
                        })
                },
                onCancel() { },
            })
        }

        const btnRequestSingle = (record, isApprove = false) => {
            if (isApprove) {
                record.isActionApprove = true
            } else {
                record.isActionReject = true
            }

            apiClient.post(`${state.endpoint.request}/${record.id}`, {
                    status: isApprove ? 'approve' : 'reject',
                })
                .then(({ data }) => {
                    message.success(data.message)

                    fetchDataList()
                })
                .finally(() => {
                    if (isApprove) {
                        record.isActionApprove = false
                    } else {
                        record.isActionReject = false
                    }
                })
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
            handleTableChange,
            onSelectChange,
            btnActionRequest,
            btnRequestSingle,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>
