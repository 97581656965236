<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Tanggal Mulai"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Tanggal Akhir"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributorCurah
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterAtbusinessSoldto
                    style="width:100%;"
                    v-model:value="state.params.soldto"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterAtbusinessShipto
                    style="width:100%;"
                    v-model:value="state.params.shipto"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    v-model:value="state.params.nomor_do"
                    allow-clear
                    mode="tags"
                    :token-separators="[',']"
                    placeholder="Nomor DO"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    v-model:value="state.params.nomor_so"
                    allow-clear
                    mode="tags"
                    :token-separators="[',']"
                    placeholder="Nomor SO"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    v-model:value="state.params.status"
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    style="width:100%;"
                    :options="state.statusLists"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
          <div class="col-lg-6 col-md-12"></div>
          <div class="col-lg-6 col-md-12 text-right">
            <a-button
                title="download excel"
                type="primary"
                @click="btnDownloadExcel"
                :loading="state.isDownloading">
                <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownloading"></i>
                <span v-else>Downloading ...</span>
            </a-button>
          </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import FilterAtbusinessShipto from '@/components/filter/FilterAtbusinessShipto'
import FilterAtbusinessSoldto from '@/components/filter/FilterAtbusinessSoldto'
import { includes } from 'lodash'
import moment from 'moment'
import {
    ROLE_KAM,
    hasRoles,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FilterProvince,
        FilterKabupatenKota,
        FilterRegional,
        FilterDistributorCurah,
        FilterAtbusinessShipto,
        FilterAtbusinessSoldto,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal Penjualan/Pengiriman',
                    dataIndex: 'tanggal_pengiriman',
                },
                {
                    title: 'Nomor DO',
                    dataIndex: 'nomor_do',
                },
                {
                    title: 'Nomor SO',
                    dataIndex: 'nomor_so',
                },
                {
                    title: 'Kode Distributor Curah',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor Curah',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Kode Sold To',
                    dataIndex: 'kode_soldto',
                },
                {
                    title: 'Nama Sold To',
                    dataIndex: 'nama_soldto',
                },
                {
                    title: 'Kode Customer Curah',
                    dataIndex: 'kode_customer',
                },
                {
                    title: 'Nama Customer Curah',
                    dataIndex: 'nama_customer',
                },
                {
                    title: 'Provinsi Customer',
                    dataIndex: 'province_name',
                },
                {
                    title: 'Area Customer',
                    dataIndex: 'area_name',
                },
                {
                    title: 'Regional Customer',
                    dataIndex: 'regional_customer',
                },
                {
                    title: 'Kode Ship To',
                    dataIndex: 'kode_shipto',
                },
                {
                    title: 'Nama Ship To',
                    dataIndex: 'nama_shipto',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'alamat',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'kode_produk',
                },
                {
                    title: 'Produk',
                    dataIndex: 'nama_produk',
                },
                {
                    title: 'Customer Group',
                    dataIndex: 'segment_customer_name',
                },
                {
                    title: 'Tanggal Jatuh Tempo Distributor',
                    dataIndex: 'jatuh_tempo_distributor',
                },
                {
                    title: 'Tanggal Jatuh Tempo Customer',
                    dataIndex: 'tanggal_jatuh_tempo',
                },
                {
                    title: 'Volume (Ton)',
                    dataIndex: 'volume',
                },
                {
                    title: 'CBP',
                    dataIndex: 'cbp',
                },
                {
                    title: 'Ongkos Angkut (OA) Perton',
                    dataIndex: 'oa',
                },
                {
                    title: 'Harga Tebus',
                    dataIndex: 'harga_tebus',
                },
                {
                    title: 'No PO',
                    dataIndex: 'po_number',
                },
                {
                    title: 'Jenis Pengiriman',
                    dataIndex: 'jenis_pengiriman',
                },
                {
                    title: 'Jenis Pembayaran dan Jaminan',
                    dataIndex: 'payment_method_and_guarantee',
                },
                {
                    title: 'Durasi Verifikasi Tagihan',
                    dataIndex: 'payment_duration',
                },
                {
                    title: 'TOP CBP',
                    dataIndex: 'top_cbp',
                },
                {
                    title: 'TOP Tebus',
                    dataIndex: 'top_redemption',
                },
                {
                    title: 'Status Penjualan/Pengiriman',
                    dataIndex: 'status_pengiriman',
                },
                {
                    title: 'Tanggal Konfirmasi',
                    dataIndex: 'tanggal_konfirmasi',
                },
                {
                    title: 'Tanggal Realisasi',
                    dataIndex: 'spj_date',
                },
            ],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                area: [],
                kabupaten: [],
                distributor: [],
                status: null,
                soldto: [],
                shipto: [],
                nomor_do: [],
                nomor_so: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).endOf("month"),
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                {
                    label: 'Dikonfirmasi',
                    value: 1,
                },
                {
                    label: 'Menunggu',
                    value: 2,
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get('/api/report/sales-curah', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // download export excel
        const btnDownloadExcel = () => {
            state.isDownloading = true
            apiClient
                .get('/api/report/sales-curah', {
                    params: {
                        ...queryParams(),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Penjualan-Distributor-Curah_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.isDownloading = false
                })
        }

        // handle vue
        onMounted(() => {
            fetchDataList()

            // menghilangkan action kolom berdasarkan role
            if (hasRoles([ROLE_KAM])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['harga_tebus'], item.dataIndex)) {
                        return item
                    }
                })
            }
        })

        return {
            fetchDataList,
            state,
            // rowSelection,
            handleTableChange,
            btnDownloadExcel,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            // only role
            ROLE_KAM,
            hasRoles,
        }
    },
})
</script>
