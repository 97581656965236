<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <FilterDistributorCurah
                    class="mr-2"
                    v-model:value="state.params.distributor"/>
                <FilterRkapMonth
                    class="mr-2"
                    v-model:value="state.params.month"/>
                <FilterRkapYear
                    class="mr-2"
                    v-model:value="state.params.years"/>
                <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:300px"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <AButton
                    v-if="hasRoles([ROLE_ADMIN_BK, ROLE_SPC_GROUP, ROLE_SPC, ROLE_CORSALES])"
                    class="mr-3"
                    title="Upload Dokumen"
                    type="primary"
                    @click="btnUpload">
                    <i class="fa fa-upload" aria-hidden="true"></i>
                </AButton>
                <AButton
                    v-if="hasRoles([ROLE_ADMIN_BK, ROLE_SPC_GROUP, ROLE_SPC, ROLE_CORSALES])"
                    class="mr-3"
                    title="Tambah Pelanggan"
                    type="primary"
                    @click="visibleModal = true">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </AButton>
                <AButton
                    title="Download Excel"
                    type="primary"
                    @click="btnDownloadExcel"
                    :loading="state.isDownload">
                    <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownload"></i>
                    <span v-else>Downloading ...</span>
                </AButton>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="id"
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #action="{ record }">
                    <span>
                        <a-tooltip title="Edit">
                            <a-button
                                class="button"
                                size="small"
                                @click="btnEdit(record)">
                                <i class="fe fe-edit" />
                            </a-button>
                        </a-tooltip>
                    </span>
                </template>
            </MdTable>
        </div>
        <FormModal
            v-if="visibleModal"
            v-model:visible="visibleModal"
            v-model:item="state.item"
            @success="fetchDataList"
        />

        <!-- upload -->
        <Upload
            v-if="visibleUploadModal"
            v-model:visible="visibleUploadModal"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import FormModal from './modal'
import FilterRkapYear from '@/components/filter/FilterRkapYear'
import FilterRkapMonth from '@/components/filter/FilterRkapMonth'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import Upload from './Upload'
import includes from 'lodash/includes'
import moment from 'moment'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_KAM,
    ROLE_SPC,
    ROLE_SPC_GROUP,
    ROLE_CORSALES,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FormModal,
        FilterRkapYear,
        FilterRkapMonth,
        FilterDistributorCurah,
        Upload,
    },
    setup() {
        const visibleModal = ref(false)
        const state = reactive({
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'Nilai RKAP',
                    dataIndex: 'rkap_value',
                    customRender: ({ text }) => {
                        return parseFloat(text).toFixed(2)
                    },
                },
                {
                    title: 'Bulan',
                    dataIndex: 'month',
                },
                {
                    title: 'Tahun',
                    dataIndex: 'years',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            data: [],
            item: null,
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownload: false,
            params: extractQueryParams({
                q: '',
                distributor: [],
                month: [],
                years: [],
                page: 1,
                "per-page": 10,
            }),
        })


        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get('/api/rkap', {
                    params: updateUrlWithQuery(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnDownloadExcel = () => {
            state.isDownload = true
            apiClient
                .get('/api/rkap', {
                    params: {
                        ...state.params,
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Master-Data-RKAP_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.isDownload = false
                })
        }

        // btn edit
        const btnEdit = (item) => {
            visibleModal.value = true
            state.item = item
        }

        // handle upload
        const visibleUploadModal = ref(false);

        const btnUpload = () => {
            visibleUploadModal.value = true
        }

        // handle vue
        onMounted(() => {
            fetchDataList()

            // show column action only this roles
            if (!hasRoles([ROLE_ADMIN_BK, ROLE_SPC_GROUP, ROLE_SPC, ROLE_CORSALES])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['Action'], item.title)) {
                        return item
                    }
                })
            }
        })

        return {
            visibleModal,
            handleTableChange,
            btnEdit,
            fetchDataList,
            state,
            visibleUploadModal,
            btnUpload,
            btnDownloadExcel,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_KAM,
            ROLE_SPC,
            ROLE_SPC_GROUP,
            ROLE_CORSALES,
        }
    },
})
</script>
<style scoped>
.card-body {
    margin-left: 20px;
}

.button {
    margin: 0 3px;
}
</style>
