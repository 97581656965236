<template>
    <AModal
        :visible="state.visible"
        title="Release Log"
        @cancel="handleModalCancel"
        width="40%"
        :destroy-on-close="true"
        :footer="null">
        <div v-for="item in state.data" :key="item.id">
            <span class="font-weight-bold" v-html="'Version ' + item.version + ' '"></span>
            <span v-html="'( ' + item.date_rilis + ' )'"></span>
            <br />
            <div class="mb-2 mt-2 ml-3">
                <span v-html="item.deskripsi"></span>
            </div>
        </div>
    </AModal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
} from 'vue'
import apiClient from '@/services/axios'

export default defineComponent({
    components: {
        // 
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {

        const state = reactive({
            endpoint: '/api/version/latest  ',
            data: [],
            visible: false,
        })

        const handleModalCancel = () => {
            state.visible = false
        }

        const fetchDataList = () => {
            apiClient
                .get(state.endpoint)
                .then(({ data }) => {
                    const { items } = data

                    if (items.length > 0) {
                        state.data = items
                        state.visible = true
                    }
                })
        }

        // handle func vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            handleModalCancel,
        }
    },
});
</script>
