<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />

    <!-- filter -->
    <ARow :gutter="[8, 8]">
      <ACol
        v-if="!isDistributor && !isDistributor_khusus"
        :xl="6" :md="8" :sm="12" :span="24">
        <FilterDistributor
          style="width:100%;"
          v-model:value="state.params.distributor"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterBrands
          style="width:100%;"
          v-model:brand="state.params.brand"
          v-model:value="state.params.brand"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterProducts
          style="width:100%;"
          v-model:brand="state.params.brand"
          v-model:value="state.params.product"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-date-picker
          style="width:100%;"
          v-model:value="state.params.date"
          placeholder="Pilih Tanggal"
          format="DD MMMM YYYY"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-input-search
          style="width:100%;"
          v-model:value="state.params.q"
          placeholder="Cari ..."/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-button
          type="primary"
          title="cari"
          @click="fetchData"
          :loading="state.isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </ACol>
    </ARow>

    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right mb-2">
        <DownloadExcel
          :url="state.endpoint"
          :params="queryParams()"
          namefile="laporan-transaksi-distributor"/>
      </div>
    </div>
    <div class="table-responsive">
      <md-table
        :columns="state.columns"
        :data-source="state.data"
        :row-selection="rowSelection"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: state.meta.total,
          pageSize: state.meta.per_page,
          current: state.meta.page,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        bordered
        @change="handleTableChange"
        :loading="state.isFetching">

        <template #no="{ index }">
          <span>
              {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
          </span>
        </template>
        <template #filterIcon="{ text: filtered }">
          <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #customer="{ text }">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
          </template>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#108ee9">Lolos</a-tag>
          <a-tag v-else color="grey">Tidak Lolos</a-tag>
        </template>
        <template #ribuan="{ text }">
          <span>{{ formatRibuan(text) }} </span>
        </template>
        <template #nilai="{ text }">
          <span>{{ text ? new Intl.NumberFormat('id-ID').format(text) : 0 }}</span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Detail Transaksi Gudang">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="detail(record.id)"
              >
                <small>
                  <i class="fe fe-info fa-lg" />
                </small>
              </a>
            </a-tooltip>

            <a-tooltip title="Syncron">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="syncron(record.id)"
              >
                <small>
                  <i class="fa fa-refresh"></i>
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterBrands from '@/components/filter/FilterBrand'
import FilterProducts from '@/components/filter/FilterProduct'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
  name: 'MFilter',
  components: {
    FilterDistributor,
    FilterBrands,
    FilterProducts,
    DownloadExcel,
  },
  setup() {
    const errorMessage = ref(null)
    const { isDistributor, vendor_id, isDistributor_khusus } = useUserInfo()
    const state = reactive({
      columns: [
        {
          title: 'No',
          slots: { customRender: 'no' },
        },
        {
          title: 'Tanggal',
          dataIndex: 'date',
        },
        {
          title: 'Tanggal Stok',
          dataIndex: 'tanggal_stock',
        },
        {
          title: 'Kode Distributor',
          dataIndex: 'distri_code',
        },
        {
          title: 'Nama Distributor',
          dataIndex: 'distri_name',
        },
        {
          title: 'Kode Produk',
          dataIndex: 'product_code',
        },
        {
          title: 'Nama Produk',
          dataIndex: 'product_name',
        },
        {
          title: 'UOM',
          dataIndex: 'uom',
        },
        {
          title: 'Stok Awal',
          dataIndex: 'stock_awal',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Sell In',
          dataIndex: 'sell_in',
          title_xls: 'Barang Masuk',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Sell Out',
          dataIndex: 'sell_out',
          title_xls: 'Barang Keluar',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Penjualan Dengan ID Toko',
          dataIndex: 'w_idbk',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Penjualan Tanpa ID Toko',
          dataIndex: 'n_idbk',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Selisih Stok',
          dataIndex: 'selisih',
          slots: { customRender: 'nilai' },
        },
      ],
      endpoint: '/api/report/transaksi-distributor',
      isFetching: false,
      params: extractQueryParams({
        q: '',
        product: [],
        brand: [],
        distributor: [],
        date: moment(new Date()).subtract(1, 'days'),
        status: null,
        page: 1,
        'per-page': 10,
      }),
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
    })

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      
      fetchData()
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.date) {
        state.params.date = moment(state.params.date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = () => {
      state.isFetching = true
      apiClient
        .get(state.endpoint, {
          params: queryParams(),
        })
        .then(response => {
          const { items, _meta } = response.data

          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    onMounted(() => {
      // validation roles default params
      if (isDistributor.value || isDistributor_khusus.value) {
        state.params.distributor.push(vendor_id.value)
      }
      
      fetchData()
    })

    const syncron = id => {
      Modal.confirm({
        title: 'Konfirmasi syncron data',
        content: 'Apakah anda ingin syncron data transaksi ?',
        onOk() {
          apiClient
            .post(`/api/synchrons/${id}`)
            .then(response => {
              const data = response.data
              console.log(data)
              formState.value = data
              console.log(formState)
            })
            .catch(e => message.error('Gagal syncron!'))
        },
        onCancel() {},
      })
    }

    const formatRibuan = value => {
      return new Intl.NumberFormat(
        'id-ID',
        { maximumFractionDigits: 2 },
        { minimumFractionDigits: 2 },
      ).format(Number(value))
    }

    return {
      handleTableChange,
      state,
      fetchData,
      queryParams,
      syncron,
      errorMessage,
      formatRibuan,
      isDistributor,
      isDistributor_khusus,
    }
  },
})
</script>
