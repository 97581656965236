<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Mulai Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Akhir Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <FilterBrand
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.brand"/>
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"
                    v-model:region="state.params.region"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:kabupaten="state.params.kabupaten"/>
                <FilterToko
                    class="mr-2"
                    v-model:value="state.params.toko"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-5">
            <div class="col-lg-12 col-md-12 mt-auto text-center">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-auto">
                                <DownloadExcel
                                    :url="state.endpoint.tonase"
                                    :params="state.params"
                                    namefile="Laporan-Data-Volume-Penjualan-Distributor-Tonase"
                                    @errors="errorMessage" />
                            </div>
                        </div>
                        <div class="my-5">
                            <ASkeleton
                                active
                                :paragraph="{ rows: 2 }"
                                :loading="state.tonase.loading">
                                <h1 class="display-1"><strong>{{ state.tonase.total }}</strong></h1>
                                <strong class="h5">Tonase Distributor</strong>
                            </ASkeleton>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body text-center">
                <strong>Tonase Distributor Per Hari</strong>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-12 col-md-12 mt-auto">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Volume Distributor PHP V3 Grafik</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.endpoint.chart"
                                    :params="state.params"
                                    namefile="Laporan-Data-Volume-Penjualan-Distributor"/>
                            </div>
                        </div>
                        <EChartCustom
                            :series="state.distributor.series"
                            :labelx="state.distributor.labelx"
                            :loading="state.distributor.loading"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch, onBeforeMount } from 'vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import FilterBrand from '@/components/filter/FilterBrand'
import EChartCustom from '@/components/charts/EChartCustom'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import { FormatCurrency } from '@/helpers/utils'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
    FilterDistributor,
    FilterRegional,
    FilterArea,
    FilterToko,
    FilterBrand,
    EChartCustom,
    DownloadExcel,
},
    setup() {
        const errorMessage = ref()

        const state = reactive({
            endpoint: {
                tonase: '/api/report/metabase-v2/tonase-distributor',
                chart: '/api/report/metabase-v2/volume-distributor',
            },
            params: extractQueryParams({
                q: '',
                region: [],
                distributor: [],
                area: [],
                kabupaten: [],
                toko: [],
                brand: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).subtract(0, 'days'),
            }),
            distributor: {
                loading: false,
                series: [],
                labelx: [],
            },
            tonase: {
                loading: false,
                total: 0,
            },
        })

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch total tonase
        const fetchTonase = () => {
            state.tonase.loading = true
            
            apiClient
                .get(state.endpoint.tonase, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    data.forEach(item => {
                        state.tonase.total = FormatCurrency(item.Tonase_Distributor)
                    })
                })
                .finally(() => {
                    state.tonase.loading = false
                })
        }

        // fetch chart list
        const fetchChart = () => {
            state.distributor.loading = true
            
            apiClient
                .get(state.endpoint.chart, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items } = data

                    state.distributor.labelx = [{
                        type: 'category',
                        boundaryGap: false,
                        data: items.map(item => item.Tanggal),
                    }]

                    state.distributor.series = [
                        {
                            name: 'Total Tonase',
                            type: 'line',
                            stack: 'Total',
                            data: items.map(item => item.Total_Tonase),
                        },
                        {
                            name: 'Total Tonase ID Toko',
                            type: 'line',
                            stack: 'Total',
                            data: items.map(item => item.Total_Tonase_ID_Toko),
                        },
                        {
                            name: 'Total Tonase Non ID Toko',
                            type: 'line',
                            stack: 'Total',
                            data: items.map(item => item.Total_Tonase_non_ID_Toko),
                        },
                    ]
                })
                .finally(() => {
                    state.distributor.loading = false
                })
        }

        const btnFetchData = () => {
            fetchChart()
            fetchTonase()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>