<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <FilterUsers
                    style="width:100%;"
                    :role_id="1260026"
                    placeholder="Pilih User KAM"
                    v-model:value="state.params.user_id"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    style="width:100%;"
                    v-model:value="state.params.active"
                    allow-clear
                    :options="state.statusLists"
                    placeholder="Pilih Status"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    style="width:100%;"
                    v-model:value="state.params.q"
                    allow-clear
                    placeholder="Cari ..."/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        title="Upload Dokumen">
                        <AButton
                            type="primary"
                            @click="btnUpload">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            namefile="Master-Data-KAM"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #status="{ text }">
                  <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                  <a-tag v-else color="grey">Tidak Aktif</a-tag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            title="Lihat"
                            placement="topLeft">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record, true)">
                                <i class="fe fe-eye" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            title="Edit"
                            placement="topLeft">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>

            </MdTable>
        </div>

        <!-- form -->
        <FormCreateOrUpdate
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- upload -->
        <Upload
            v-if="visibleUploadModal"
            v-model:visible="visibleUploadModal"
            @success="fetchDataList"/>
            
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import FilterUsers from '@/components/filter/FilterUsers'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FormCreateOrUpdate from './FormCreateOrUpdate'
import Upload from './Upload'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FormCreateOrUpdate,
        DownloadExcel,
        FilterUsers,
        Upload,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    dataIndex: 'no',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Nomor Pegawai',
                    dataIndex: 'nopeg',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                },
                {
                    title: 'Nama Lengkap',
                    dataIndex: 'fullname',
                },
                {
                    title: 'Nomor Telepon',
                    dataIndex: 'phone',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/user-kam',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                user_id: [],
                active: null,
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                {
                    label: 'Aktif',
                    value: 1,
                },
                {
                    label: 'Nonaktif',
                    value: 0,
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return {
                ...updateUrlWithQuery(params),
                active: state.params.active,
            }
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle upload
        const visibleUploadModal = ref(false);

        const btnUpload = () => {
            visibleUploadModal.value = true
        }

        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            handleTableChange,
            state,
            errorMessage,
            visibleFormModal,
            visibleFormItemModal,
            btnForm,
            queryParams,
            visibleUploadModal,
            btnUpload,
        }
    },
})
</script>
